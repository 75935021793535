import { useWidth } from '../../Hooks'
import { certif, juridique, phone, tools } from '../../assets/icons'
import '../../styles/partenaire.css'

function PartenaireHome() {
	const width = useWidth()
	return (
		<>
			{width < 1000 && width > 600 && <div className="padding-top"></div>}
			<div className="top-right-number">
				<small className="headerFont">Besoins d'aide ? </small>
				<img src={phone} alt="phone" />
				<span className="headerFont color-primary">0143967518</span>
			</div>
			<div className="buttons-header">
				<div className="button-header">
					<h1>2</h1>
					<h2>Chantiers en cours</h2>
					<p>Afficher les détails</p>
				</div>
				<div className="button-header">
					<h1>3</h1>
					<h2>Projets à contacter</h2>
					<p>Afficher les détails</p>
				</div>
			</div>

			<h3 className="chantier-title">Nombre de chantier en cours</h3>
			<div className="chantier-table">
				<div className="chantier-row">
					<h4>Nom</h4>
					<h4>Prénom</h4>
					<h4>N° Tél</h4>
					<h4>Type de projet à faire</h4>
				</div>
				<div className="chantier-row">
					<h3>Nom</h3>
					<h3>Prénom</h3>
					<h3>N° Tél</h3>
					<h3>Type de projet à faire</h3>
				</div>
			</div>
			<div className="big-button">
				<h1>0</h1>
				<h2>Chantier clôturé</h2>
				<p>Afficher les détails</p>
			</div>
			<h1 className="demande-particulier-title">Une demande particulière ?</h1>
			<div className="buttons-footer">
				<button>
					<img src={juridique} alt="juridique" />
					<h2>Service d’accompagnement Juridique</h2>
					<small>Afificher les détails</small>
				</button>
				<button id="btn-primary">
					<img src={tools} alt="tools" />
					<h2>Service d’accompagnement Technique</h2>
					<small>Afificher les détails</small>
				</button>
				<button>
					<img src={certif} alt="juridique" />
					<h2>Formation</h2>
					<small>Afificher les détails</small>
				</button>
			</div>
		</>
	)
}

export default PartenaireHome
