import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useWidth } from '../../Hooks'

function DevisForm({ formData, setFormData, showErrorMessage }) {
	const [error, setError] = useState({
		email: false,
		code_postal: false,
		telephone: false,
	})
	const width = useWidth()
	const checkCodePostal = number => {
		if (
			number.length !== 5 ||
			parseInt(number) < 0 ||
			isNaN(parseInt(number))
		) {
			toast.error('Veuillez entrer un code postal valide')
			setError({
				...error,
				code_postal: true,
			})
		} else {
			setError({
				...error,
				code_postal: false,
			})
		}
	}
	const checkPhoneNumber = number => {
		if (
			number.length !== 10 ||
			parseInt(number) < 0 ||
			isNaN(parseInt(number))
		) {
			toast.error('Veuillez entrer un numéro de téléphone valide')
			setError({
				...error,
				telephone: true,
			})
		} else {
			setError({
				...error,
				telephone: false,
			})
		}
	}
	const checkEmail = email => {
		if (!email.includes('@')) {
			setError({
				...error,
				email: true,
			})
			toast.error('Veuillez entrer une adresse e-mail valide')
		} else {
			setError({
				...error,
				email: false,
			})
		}
	}
	return (
		<div className="flex column width-80 center ">
			<div className="header-devis">
				<h1 className="header-title">BIENVENUE</h1>
				<h2 className="header-subtitle">Décrivez nous vos besoins</h2>
			</div>
			<p className="description-devis">
				Posez ici en quelques clics les premières briques de votre projet. Un
				expert travaux prendra contact avec vous sous 48H, avant de vous
				proposer au moins deux artisans de confiance et proches de chez vous.
			</p>
			<div className="form-container margin-0-auto flex  space-around">
				<div className="form-section">
					<div className="input-container">
						<label htmlFor="nom" className="label-text">
							Nom <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label ${
								showErrorMessage && !formData.nom && 'red-border'
							}`}
							placeholder="Nom"
							type="text"
							value={formData.nom}
							onChange={e =>
								setFormData({
									...formData,
									nom: e.target.value,
								})
							}
							id="nom"
						/>
					</div>
					<div className="input-container">
						<label htmlFor="prenom" className="label-text">
							Prenom <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label ${
								showErrorMessage && !formData.prenom && 'red-border'
							}`}
							placeholder="Prenom"
							type="text"
							value={formData.prenom}
							onChange={e =>
								setFormData({
									...formData,
									prenom: e.target.value,
								})
							}
							id="prenom"
						/>
					</div>
				</div>
				<div className="form-section">
					<div className="input-container">
						<label htmlFor="E-mail" className="label-text">
							E-mail <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label ${
								((showErrorMessage && !formData.email) || error.email) &&
								'red-border'
							}`}
							placeholder="E-mail"
							type="email"
							id="E-mail"
							value={formData.email}
							onBlur={() => checkEmail(formData.email)}
							onChange={e =>
								setFormData({
									...formData,
									email: e.target.value,
								})
							}
						/>
					</div>
					<div className="input-container">
						<label htmlFor="Telephone" className="label-text">
							Numéro de téléphone <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label ${
								((showErrorMessage && !formData.telephone) ||
									error.telephone) &&
								'red-border'
							}`}
							placeholder="ex : 0806852315"
							type="number"
							value={formData.telephone}
							onBlur={() => {
								checkPhoneNumber(formData.telephone)
							}}
							onChange={e =>
								setFormData({
									...formData,
									telephone: e.target.value,
								})
							}
							id="Telephone"
						/>
					</div>
				</div>
				<div className={width > 1000 ? 'form-section ' : 'form-section rows'}>
					<div className="input-container input-container-fully">
						<label htmlFor="Adresse" className="label-text">
							Adresse <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label width-adresse ${
								showErrorMessage && !formData.adresse && 'red-border'
							}`}
							placeholder="Adresse"
							type="text"
							value={formData.adresse}
							onChange={e =>
								setFormData({
									...formData,
									adresse: e.target.value,
								})
							}
							id="Adresse"
						/>
					</div>
					<div className="input-container">
						<div className="two-rows ">
							<div className="input-container">
								<label htmlFor="ville" className="label-text">
									Ville <span className="color-primary">*</span>
								</label>
								<input
									className={`input-label ${
										showErrorMessage && !formData.ville && 'red-border'
									}`}
									placeholder="Ville"
									type="text"
									value={formData.ville}
									list="villes"
									onChange={e =>
										setFormData({
											...formData,
											ville: e.target.value,
										})
									}
									id="ville"
								/>
							</div>
							<div className="input-container">
								<label htmlFor="CodePostal" className="label-text">
									Code Postal <span className="color-primary">*</span>
								</label>
								<input
									className={`input-label ${
										((showErrorMessage && !formData.code_postal) ||
											error.code_postal) &&
										'red-border'
									}`}
									placeholder="10000"
									type="number"
									onBlur={() => {
										checkCodePostal(formData.code_postal)
									}}
									value={formData.code_postal}
									onChange={e =>
										setFormData({
											...formData,
											code_postal: e.target.value,
										})
									}
									id="CodePostal"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DevisForm
