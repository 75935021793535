import { useWidth } from '../../Hooks'
import {
	phone,
	timeLine,
	line,
	lineColor,
	okParticulier,
} from '../../assets/icons'

import '../../styles/particulier.css'
import { upload } from '../../assets/devisIcons'

function Particulier() {
	const width = useWidth()
	return (
		<>
			{width < 1000 && width > 600 && <div className="padding-top"></div>}
			<div className="top-right-number">
				<small className="headerFont">Besoins d'aide ? </small>
				<img src={phone} alt="phone" />
				<span className="headerFont color-primary">0143967519</span>
			</div>
			<div className="particulier-container">
				<h1>suivi de votre chantier</h1>
				<div className="schema-container">
					<div className="schema-left">
						<div className="step-left-container">
							<h1 id="primary-color">01.</h1>
							<h3>Formulaire à remplir</h3>
							<div className="step-finished">
								<img src={okParticulier} alt="ok" />
								étape effectuée
							</div>
						</div>
						<div className="line-content">
							<img src={lineColor} alt="line" className="line" />
						</div>
						<div className="step-left-container">
							<h1>03.</h1>
							<h3>Calculez vos primes </h3>
						</div>
						<div className="line-content">
							<img src={line} alt="line" className="line" />
							<p className="prime-cee">Prime CEE = 000</p>
							<p className="prime-renov">Prime Renov = 000</p>
						</div>
						<div className="step-left-container">
							<h1>05.</h1>
							<h3>Fin du chantier </h3>
						</div>
					</div>

					<img src={timeLine} alt="timeline" className="time-line" />
					<div className="schema-right">
						<div className="step-right-container">
							<h1>02.</h1>
							<h3>Montant de vos travaux </h3>
						</div>
						<div className="line-content">
							<p className="montant-devis">Montant du Devis = 0000</p>
							<img src={line} alt="line" className="line" />
						</div>
						<div className="step-right-container">
							<h1>04.</h1>
							<h3>Début des travaux </h3>
						</div>
						<div className="line-content">
							<p className="debut-travaux">
								Date de debut des travaux : JJ/MM/AA
							</p>
							<p className="visite-bureau">
								Date visite du bureau de controle : JJ/MM/AA
							</p>
							<img src={line} alt="line" className="line" />
						</div>
						<div className="download-content">
							<div className="download-row">
								<img src={upload} alt="upload" />
								<p>Télécharger votre attestation ﬁn de travaux</p>
							</div>
							<div className="download-row">
								<img src={upload} alt="upload" />
								<p>Télécharger votre facture</p>
							</div>
							<div className="download-row">
								<img src={upload} alt="upload" />
								<p>Télécharger votre devis</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Particulier
