import { useState, useEffect, useRef } from 'react'
import { FormHeader, UploadFiles } from '../components/partenaire'
import { useWidth } from '../Hooks'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import gsap from 'gsap'
import { submitPartenaire } from '../store/actions/guestActions'

function DevenirPartenaire() {
	const progressBarRef = useRef(null)
	const dispatch = useDispatch()
	const width = useWidth()
	const { progress } = useSelector(state => state.guest)
	const [showErrorMessage, setShowErrorMessage] = useState(false)
	const { pathname } = useLocation()
	useEffect(() => {
		gsap.to(progressBarRef.current, { width: `${progress}vw` })
	}, [progress])

	useEffect(() => {
		window.scrollTo({
			top: 0,
		})
	}, [pathname])
	const [formData, setFormData] = useState({
		siret: '',
		raison_sociale: '',
		email: '',
		telephone: '',
		adresse: '',
		ville: '',
		code_postal: '',
	})
	const [files, setFiles] = useState([])
	const [reset, setReset] = useState(false)
	const validateNumber = number => {
		if (number.length !== 10 || parseInt(number) < 0 || isNaN(parseInt(number)))
			return false
		return true
	}
	const validateEmail = email => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	}
	const validateCodePostal = number => {
		if (number.length !== 5 || parseInt(number) < 0 || isNaN(parseInt(number)))
			return false
		return true
	}
	const isFormValid = () => {
		let valid = true
		for (const property in formData) {
			if (property === 'email') valid = validateEmail(property[property])

			if (property === 'code_postal')
				valid = validateCodePostal(formData[property])
			if (property === 'telephone') valid = validateNumber(formData[property])
			else if (!formData[property]) valid = false
		}
		return valid
	}
	const handleSubmite = e => {
		e.preventDefault()
		if (!isFormValid()) {
			toast.error('Veuiller remplir le formulaire')
			setShowErrorMessage(true)
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
			return
		}

		dispatch(submitPartenaire(JSON.stringify({ ...formData, files })))
		toast.success('Votre demande a été envoyé')

		//reset Form
		setFormData({
			siret: '',
			raison_sociale: '',
			email: '',
			telephone: '',
			adresse: '',
			ville: '',
			code_postal: '',
		})
		setFiles([])
		setReset(true)
	}
	return (
		<>
			{progress > 0 && <div className="progressBar" ref={progressBarRef} />}
			<form onSubmit={e => handleSubmite(e)}>
				{width < 1000 && width > 600 && <div className="padding-top"></div>}
				<FormHeader
					showErrorMessage={showErrorMessage}
					formData={formData}
					setFormData={setFormData}
					validateNumber={validateNumber}
					validateEmail={validateEmail}
					validateCodePostal={validateCodePostal}
				/>
				<UploadFiles
					reset={reset}
					setReset={setReset}
					files={files}
					siret={formData.siret}
					setFiles={setFiles}
					showErrorMessage={showErrorMessage}
				/>
			</form>
		</>
	)
}

export default DevenirPartenaire
