import { useState, useEffect } from 'react'
import { logoWhite } from '../assets/icons'
import { Condition, Confidentialite } from '../components/devis/models'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserSession } from '../store/actions/authActions'

function Footer() {
	const { isSignedIn, profile } = useSelector(state => state.user)
	const dispatch = useDispatch()
	const [modals, setModals] = useState({
		condition: false,
		confitentialite: false,
	})
	useEffect(() => {
		dispatch(getUserSession())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSignedIn])
	return (
		<div className="flex column center height-40 bg-secondary">
			<img src={logoWhite} alt="logo outlined" className="logo-footer" />
			<div className="flex space-evenly width-70">
				{isSignedIn && profile === 'Particulier' && (
					<Link className="color-white font-flexible web-list " to="/">
						Espace Particulier
					</Link>
				)}
				{isSignedIn && profile === 'Partenaire' && (
					<Link className="color-white font-flexible web-list " to="/">
						Espace Partenaire RGE
					</Link>
				)}
				{!isSignedIn && (
					<>
						<Link to="/devis" className="color-white font-flexible web-list">
							Espace Particulier
						</Link>
						<Link
							to="/devenir-partenaire"
							className="color-white font-flexible web-list "
						>
							Espace Partenaire RGE
						</Link>
					</>
				)}
				<Link className="color-white font-flexible web-list " to="/solutions">
					Nos solutions
				</Link>
				<Link className="color-white font-flexible web-list " to="/contact">
					Contact
				</Link>
			</div>
			<span className="color-white footer-text ">
				Mentions légales -{' '}
				<span
					className="pointer"
					onClick={() => setModals({ ...modals, condition: true })}
				>
					Conditions Générales d’Utilisation
				</span>{' '}
				-{' '}
				<span
					className="pointer"
					onClick={() => setModals({ ...modals, confitentialite: true })}
				>
					{' '}
					Vie Privée
				</span>
			</span>
			{modals.condition && (
				<Condition
					conditionEtConfidentialite={modals}
					setConditionEtConfidentialite={setModals}
				/>
			)}
			{modals.confitentialite && (
				<Confidentialite
					conditionEtConfidentialite={modals}
					setConditionEtConfidentialite={setModals}
				/>
			)}
		</div>
	)
}

export default Footer
