import React from 'react'
import { closeNav, logoWhite } from '../../assets/icons'
import { Link } from 'react-router-dom'

function MenuRight({ setNavMenu }) {
	return (
		<>
			<div className="navMenu">
				<img src={logoWhite} className="logo-outlined" alt="logo" />

				<li className="phone-list">Espace Particulier </li>
				<li className="phone-list">Espace Partenaire RGE </li>
				<Link
					to="/solutions"
					onClick={() => setNavMenu(false)}
					className="phone-list"
				>
					Nos solutions{' '}
				</Link>
				<Link
					to="/contact"
					onClick={() => setNavMenu(false)}
					className="phone-list"
				>
					Contact{' '}
				</Link>
				<Link
					to="/se-connecter"
					onClick={() => setNavMenu(false)}
					className="phone-list"
				>
					Se connecter
				</Link>
			</div>
			<img
				src={closeNav}
				onClick={() => setNavMenu(false)}
				alt="close navigation"
				className="nav-logo"
			/>
		</>
	)
}

export default MenuRight
