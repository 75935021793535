import { useState, useEffect } from 'react'
import Kbis from './Kbis'
import Qualibat from './Qualibat'
import Cibtp from './Cibtp'
import Assurance from './Assurance'
import Urssaf from './Urssaf'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

function UploadFiles({
	files,
	siret,
	reset,
	setReset,
	setFiles,
	showErrorMessage,
}) {
	const [kbis, setKbis] = useState(null)
	const [qualibat, setQualibat] = useState(null)
	const [cibtp, setCibtp] = useState(null)
	const [assurance, setAssurance] = useState(null)
	const [urssaf, setUrssaf] = useState(null)
	useEffect(
		() => {
			setFiles([kbis, qualibat, cibtp, urssaf, assurance])
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[kbis, qualibat, cibtp, urssaf, assurance]
	)
	useEffect(() => {
		if (reset) {
			setKbis(null)
			setQualibat(null)
			setCibtp(null)
			setAssurance(null)
			setUrssaf(null)
			setReset(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reset])

	const showErrorNotification = () => {
		if (!siret) {
			toast.error("Veuillez entrer Siret avant d'ajouter les fichiers")
		}
	}
	const { progress } = useSelector(state => state.guest)

	return (
		<div className="upload-container">
			<Kbis
				kbis={kbis}
				setKbis={setKbis}
				showErrorMessage={showErrorMessage}
				siret={siret}
				showErrorNotification={showErrorNotification}
			/>
			<Qualibat
				qualibat={qualibat}
				setQualibat={setQualibat}
				showErrorMessage={showErrorMessage}
				siret={siret}
				showErrorNotification={showErrorNotification}
			/>
			<Assurance
				assurance={assurance}
				setAssurance={setAssurance}
				showErrorMessage={showErrorMessage}
				siret={siret}
				showErrorNotification={showErrorNotification}
			/>
			<Urssaf
				urssaf={urssaf}
				setUrssaf={setUrssaf}
				showErrorMessage={showErrorMessage}
				siret={siret}
				showErrorNotification={showErrorNotification}
			/>
			<Cibtp
				cibtp={cibtp}
				setCibtp={setCibtp}
				showErrorMessage={showErrorMessage}
				siret={siret}
				showErrorNotification={showErrorNotification}
			/>
			<button
				disabled={
					kbis === null || qualibat === null || assurance === null || progress
						? true
						: false
				}
				type="submit"
				className={`submit-button margin-top-1rem ${
					(kbis === null ||
						qualibat === null ||
						assurance === null ||
						progress) &&
					'disabled'
				}`}
			>
				DEVENIR PARTENAIRE
			</button>
			<p className="p-submit">
				Un expert travaux prendra contact avec vous sous 48H
			</p>
		</div>
	)
}

export default UploadFiles
