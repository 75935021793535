import React, { useState, useEffect } from 'react'
import { closeNav, ok } from '../../../assets/icons'

function PrimeModal({
	openModels,
	setOpenModels,
	reset,
	setReset,
	addBesoinObjet,
}) {
	const [options, setOptions] = useState({
		nom: 'Prime',
		option: [],
	})
	const optionInOption = choice => {
		return options.option.find(elemment => elemment === choice) ? true : false
	}
	const addToOptions = newOption => {
		if (!optionInOption(newOption)) {
			setOptions({
				...options,
				option: [...options.option, newOption],
			})
		} else {
			setOptions({
				...options,
				option: options.option.filter(choix => choix !== newOption),
			})
		}
	}

	useEffect(() => {
		if (reset) {
			setReset(false)
			setOptions({
				...options,
				option: [],
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reset])
	useEffect(() => {
		if (openModels.PrimeModalIsOpen) document.body.style.overflow = 'hidden'
		else document.body.style.overflow = 'unset'
	}, [openModels.PrimeModalIsOpen])

	if (!openModels.PrimeModalIsOpen) {
		return null
	}
	return (
		<>
			<div
				className="overlay"
				onClick={() =>
					setOpenModels({ ...openModels, PrimeModalIsOpen: false })
				}
			/>
			<div className="modal">
				<div className="headerNavigation">
					<img
						src={ok}
						alt="fermer la navigation"
						className="close-Model"
						onClick={() => {
							addBesoinObjet(options)
							setOpenModels({ ...openModels, PrimeModalIsOpen: false })
						}}
					/>
					<img
						src={closeNav}
						alt="fermer la navigation"
						className="close-Model"
						onClick={() =>
							setOpenModels({ ...openModels, PrimeModalIsOpen: false })
						}
					/>
				</div>
				<label className="label-text margin-top-1rem">Combien ma prime ?</label>
				<div className="card-form-container margin-bottom-1rem margin-top-05rem">
					<div
						className={
							optionInOption('Ma prime renov')
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							addToOptions('Ma prime renov')
						}}
					>
						Ma prime renov
					</div>
					<div
						className={
							optionInOption('CEE')
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							addToOptions('CEE')
						}}
					>
						CEE
					</div>
					<div
						className={
							optionInOption('Aide Etatique')
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							addToOptions('Aide Etatique')
						}}
					>
						Aide Etatique
					</div>
					<div
						className={
							optionInOption('Aide Regionale')
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							addToOptions('Aide Regionale')
						}}
					>
						Aide Régionale
					</div>
					<div
						className={
							optionInOption('Aide Départementale')
								? 'card-form width-card height-9rem text-overflow bg-linear color-white'
								: 'card-form width-card height-9rem text-overflow'
						}
						onClick={() => {
							addToOptions('Aide Départementale')
						}}
					>
						Aide Départementale
					</div>
				</div>
			</div>
		</>
	)
}

export default PrimeModal
