import React from 'react'
import { arrowSend } from '../../assets/icons'
import { useWidth } from '../../Hooks'
const data = [
	{
		id: 1,
		img: 'https://img.batiweb.com/repo-images/article/38371/construction.jpeg',
		hashtag: "Tarifs de l'energie",
		title: '+12,6% : les tarifs du gaz',
		date: '30 septembre 2021',
	},
	{
		id: 2,
		img: 'https://fac.img.pmdstatic.net/fit/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2FFAC.2Fvar.2Ffemmeactuelle.2Fstorage.2Fimages.2Factu.2Fvie-pratique.2Ffacilotab-tablette-connnecte-seniors-45141.2F14786576-1-fre-FR.2Ffacilotab-la-tablette-qui-connecte-les-seniors.2Ejpg/850x478/quality/90/crop-from/center/facilotab-la-tablette-qui-connecte-les-seniors.jpeg',
		hashtag: "Tarifs de l'energie",
		title: '+12,6% : les tarifs du gaz',
		date: '18 septembre 2021',
	},
	{
		id: 3,
		img: 'https://www.studyrama.com/local/cache-vignettes/L990xH363/arton106828-8d6c1.jpg',
		hashtag: "Tarifs de l'energie",
		title: 'Ecologie',
		date: '15 septembre 2021',
	},
]
const SideCard = ({ deuxiemActu, troisiemeActu }) => (
	<>
		<div className="actus-card hight-180">
			<img
				className="width-card-img"
				alt={deuxiemActu.title}
				src={deuxiemActu.img}
			/>
			<div className="flex card-content ">
				<div className="infos-card">
					<span className="hashtag">{deuxiemActu.hashtag}</span>
					<span className="infos-title">{deuxiemActu.title}</span>
				</div>
				<span className="center-self">{deuxiemActu.date} </span>
			</div>
		</div>
		<div className="actus-card hight-180">
			<img
				className="width-card-img"
				alt={troisiemeActu.title}
				src={troisiemeActu.img}
			/>
			<div className="flex card-content">
				<div className="infos-card">
					<span className="hashtag">{troisiemeActu.hashtag}</span>
					<span className="infos-title">{troisiemeActu.title}</span>
				</div>
				<span className="center-self">{troisiemeActu.date} </span>
			</div>
		</div>
	</>
)
function Actualite() {
	const premierActu = {
		...data[0],
	}
	const deuxiemActu = {
		...data[1],
	}
	const troisiemeActu = {
		...data[2],
	}
	const width = useWidth()
	return (
		<div className="flex column bg-whiteblue">
			<div className="flex column center margin-top-2">
				<h1 className="no-margin color-secondary big-font ">
					Les actualités de la rénovation
				</h1>
				<hr className="border-Bottom" />
			</div>
			<div className="actus-cards">
				<div className="actus-card hight-400">
					<img
						className="width-card-img big-image"
						alt={premierActu.title}
						src={premierActu.img}
					/>
					<div className="flex card-content ">
						<div className="infos-card small-content">
							<span className="hashtag">{premierActu.hashtag}</span>
							<span className="infos-title">{premierActu.title}</span>
						</div>
						<span className="center-self">{premierActu.date} </span>
					</div>
				</div>
				{width < 1000 && (
					<SideCard deuxiemActu={deuxiemActu} troisiemeActu={troisiemeActu} />
				)}
				<div className="flex column space-around">
					{width > 1000 && (
						<SideCard deuxiemActu={deuxiemActu} troisiemeActu={troisiemeActu} />
					)}
				</div>
			</div>
			<span className="right">
				<img src={arrowSend} alt="arrow" className="arrow-article" /> Voir tous
				les articles
			</span>
			<div className="spacing" />
		</div>
	)
}

export default Actualite
