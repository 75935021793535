import React, { useState } from 'react'
import { getUserIp } from '../../store/actions/guestActions'
import { useDispatch } from 'react-redux'
import { checkedWhite } from '../../assets/devisIcons'
import { Condition, Confidentialite } from './models'
import { toast } from 'react-toastify'

function FooterForm({
	FooterData,
	setFooterData,
	checked,
	setChecked,
	showErrorMessage,
	email,
}) {
	const [termsAccepted, setTermsAccepted] = useState(false)

	const [conditionEtConfidentialite, setConditionEtConfidentialite] = useState({
		condition: false,
		confitentialite: false,
	})
	const dispatch = useDispatch()
	const handleCheck = () => {
		if (email.includes('@')) {
			setChecked(!checked)
			if (!termsAccepted) {
				dispatch(getUserIp(email))
				setTermsAccepted(true)
			}
		} else {
			toast.error('Veuillez entrer une adresse e-mail')
		}
	}

	return (
		<div className="flex column width-80">
			<div className="input-container">
				<label htmlFor="nom" className="label-text">
					Surface du logement ?
				</label>
				<input
					className="input-label"
					type="number"
					placeholder="100 m²"
					value={FooterData.surface_logement}
					onChange={e => {
						setFooterData({
							...FooterData,
							surface_logement: e.target.value,
						})
					}}
					id="nom"
				/>
			</div>
			<label className="label-text margin-top-05rem">
				Tranche de revenu fiscal ?
			</label>
			<div className="card-form-container margin-top-1rem">
				<button
					className={
						FooterData.tranche_revenu_fiscal === 'Moins de 30 572€'
							? 'card-form width-32p small bg-linear color-white'
							: 'card-form width-32p small'
					}
					onClick={() =>
						setFooterData({
							...FooterData,
							tranche_revenu_fiscal: 'Moins de 30 572€',
						})
					}
					type="button"
				>
					Moins de 30 572€
				</button>
				<button
					className={
						FooterData.tranche_revenu_fiscal === 'Entre 30 572€ et 39 192€'
							? 'card-form width-32p small bg-linear color-white'
							: 'card-form width-32p small'
					}
					onClick={() =>
						setFooterData({
							...FooterData,
							tranche_revenu_fiscal: 'Entre 30 572€ et 39 192€',
						})
					}
					type="button"
				>
					Entre 30 572€ et 39 192€
				</button>
				<button
					className={
						FooterData.tranche_revenu_fiscal === 'Plus de de 39 192€'
							? 'card-form width-32p small bg-linear color-white'
							: 'card-form width-32p small'
					}
					onClick={() =>
						setFooterData({
							...FooterData,
							tranche_revenu_fiscal: 'Plus de de 39 192€',
						})
					}
					type="button"
				>
					Plus de de 39 192€
				</button>
			</div>
			<div className="submit">
				<div className="checkBoxContainer">
					<div
						className={`checkBox center ${checked && 'bg-primary'}`}
						onClick={() => {
							handleCheck()
						}}
					>
						<img src={checkedWhite} alt="checked" className="check-icon" />
					</div>
					<div className="font-condition">
						Accepter les{' '}
						<span
							className="color-blue-underline"
							onClick={() =>
								setConditionEtConfidentialite({
									...conditionEtConfidentialite,
									condition: true,
								})
							}
						>
							conditions générales d'utilisation
						</span>{' '}
						et{' '}
						<span
							className="color-blue-underline"
							onClick={() =>
								setConditionEtConfidentialite({
									...conditionEtConfidentialite,
									confitentialite: true,
								})
							}
						>
							politique de confidentialité
						</span>
					</div>
				</div>
				{!checked && showErrorMessage && (
					<label className="label-text p-submit color-red margin-top-05rem">
						Veuillez accepter les conditions générales d'utilisation
					</label>
				)}
				<button type="submit" className="submit-button margin-top-1rem">
					DEMANDER DES DEVIS
				</button>
				<p className="p-submit">
					Un expert travaux prendra contact avec vous sous 48H
				</p>
			</div>
			{conditionEtConfidentialite.condition && (
				<Condition
					conditionEtConfidentialite={conditionEtConfidentialite}
					setConditionEtConfidentialite={setConditionEtConfidentialite}
				/>
			)}
			{conditionEtConfidentialite.confitentialite && (
				<Confidentialite
					conditionEtConfidentialite={conditionEtConfidentialite}
					setConditionEtConfidentialite={setConditionEtConfidentialite}
				/>
			)}
		</div>
	)
}

export default FooterForm
