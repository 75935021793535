import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { email, arrowSend } from '../../assets/icons'
import axios from 'axios'

function Email() {
	const [emailError, setEmailError] = useState(false)
	const [emailAdress, setEmailAdress] = useState('')
	const send = async () => {
		const data = JSON.stringify({
			email: emailAdress,
			typeEvenement: 'surprise',
		})
		if (emailAdress.includes('@')) {
			try {
				await axios.post(
					`${process.env.REACT_APP_URL_API_GAGNER_SURPRISE}`,
					data
				)
				toast.success('Votre email a été bien envoyé')
				setEmailError(false)
				setEmailAdress('')
			} catch (err) {
				toast.error("Une erreur s'est produite veuillez réessayer")
				setEmailError(true)
			}
		} else {
			toast.error('Veuillez entrer une adresse e-mail valide')
			setEmailError(true)
		}
	}
	return (
		<div
			id="center-content"
			className="flex column center margin-top-3rem height-30r  bg-linear"
		>
			<h1 className="no-margin big-font color-white">Restez informés !</h1>
			{/* <h3 className="no-margin medium-font text-center color-white">
				Tentez vos chances de gagner un de nos produits gratuitement et des
				offres spéciales en entrant votre adresse mail.
			</h3> */}
			<div className={`input ${emailError && 'red-border'}`}>
				<img src={email} alt="email" />
				<input
					onBlur={() => send()}
					className="inputMail"
					type="email"
					placeholder="Adresse email"
					value={emailAdress}
					onChange={e => setEmailAdress(e.target.value)}
				/>
				<img src={arrowSend} alt="send" className="fill-primary" />
			</div>
		</div>
	)
}

export default Email
