import React, { useEffect } from 'react'
import { closeNav } from '../../../assets/icons'

function Confidentialite({
	setConditionEtConfidentialite,
	conditionEtConfidentialite,
}) {
	useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => (document.body.style.overflow = 'unset')
	}, [])
	return (
		<>
			<div
				className="overlay"
				onClick={() =>
					setConditionEtConfidentialite({
						...conditionEtConfidentialite,
						confitentialite: false,
					})
				}
			/>
			<div className="modal">
				<div className="headerNavigation">
					<img
						src={closeNav}
						alt="fermer la navigation"
						className="close-Model"
						onClick={() =>
							setConditionEtConfidentialite({
								...conditionEtConfidentialite,
								confitentialite: false,
							})
						}
					/>
				</div>
				<p>Politique de confidentialité de Global Digital Ecology</p>
				<p>
					La présente Politique de confidentialité décrit la façon dont vos
					informations personnelles sont recueillies, utilisées et partagées
					lorsque vous vous rendez sur https://globaldigitaleco.fr/
				</p>
				<p>INFORMATIONS PERSONNELLES RECUEILLIES</p>
				<p>
					Lorsque vous vous rendez sur le Site, nous recueillons automatiquement
					certaines informations concernant votre appareil, notamment des
					informations sur votre navigateur web, votre adresse IP, votre fuseau
					horaire et certains des cookies qui sont installés sur votre appareil.
					En outre, lorsque vous parcourez le Site, nous recueillons des
					informations sur les pages web ou produits individuels que vous
					consultez, les sites web ou les termes de recherche qui vous ont
					permis d'arriver sur le Site, ainsi que des informations sur la
					manière dont vous interagissez avec le Site. Nous désignons ces
					informations collectées automatiquement sous l'appellation «
					Informations sur l'appareil ».
				</p>
				<p>
					Nous recueillons les Informations sur l'appareil à l'aide des
					technologies suivantes :
				</p>
				<p>FICHIERS TÉMOINS (COOKIES)</p>
				<p>
					Voici une liste de fichiers témoins que nous utilisons. Nous les avons
					énumérés ici pour que vous ayez la possibilité de choisir si vous
					souhaitez les autoriser ou non.
				</p>
				<p>
					- Les « fichiers journaux » suivent l'activité du Site et recueillent
					des données telles que votre adresse IP, le type de navigateur que
					vous utilisez, votre fournisseur d'accès Internet, vos pages
					référentes et de sortie, et vos données d'horodatage (date et heure).
					<br />- Les « pixels invisibles », les « balises » et les « pixels »
					sont des fichiers électroniques qui enregistrent des informations sur
					la façon dont vous parcourez le Site.Par ailleurs, lorsque vous
					effectuez ou tentez d'effectuer un achat par le biais du Site, nous
					recueillons certaines informations vous concernant, notamment votre
					nom, votre adresse de facturation, votre adresse votre adresse e-mail
					et votre numéro de téléphone. Ces informations collectées
					automatiquement sont désignées par l’appellation « Informations sur le
					chantier ».
				</p>
				<p>
					Lorsque nous utilisons l'expression « Informations personnelles » dans
					la présente Politique de confidentialité, nous faisons allusion à la
					fois aux Informations sur l'appareil et aux Informations sur le
					chantier.
				</p>
				<p>COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES ?</p>
				<p>
					En règle générale, nous utilisons les Informations sur le chantier que
					nous recueillons pour traiter toute demande de devis passée par le
					biais du Site. En outre, nous utilisons ces Informations sur le
					chantier pour :
				</p>
				<p>
					Communiquer avec vous ;<br />
					Évaluer les fraudes ou risques potentiels ; etLorsque cela correspond
					aux préférences que vous nous avez communiquées, vous fournir des
					informations ou des publicités concernant nos produits ou services.
				</p>
				<p>
					Nous utilisons les Informations sur l'appareil (en particulier votre
					adresse IP) que nous recueillons pour évaluer les fraudes ou risques
					potentiels et, de manière plus générale, pour améliorer et optimiser
					notre Site (par exemple, en générant des analyses sur la façon dont
					nos clients parcourent et interagissent avec le Site, et pour évaluer
					la réussite de nos campagnes de publicité et de marketing).
				</p>
				<p>PARTAGE DE VOS INFORMATIONS PERSONNELLES</p>
				<p>
					Nous partageons vos Informations personnelles avec des tiers qui nous
					aident à les utiliser aux fins décrites précédemment. Par exemple,nous
					utilisons également Google Analytics pour mieux comprendre comment nos
					clients utilisent le Site – pour en savoir plus sur l'utilisation de
					vos Informations personnelles par Google, veuillez consulter la page
					suivante : https://www.google.com/intl/fr/policies/privacy/. Vous
					pouvez aussi désactiver Google Analytics ici :
					https://tools.google.com/dlpage/gaoptout.
				</p>
				<p>
					Enfin, il se peut que nous partagions aussi vos Informations
					personnelles pour respecter les lois et règlementations applicables,
					répondre à une assignation, à un mandat de perquisition ou à toute
					autre demande légale de renseignements que nous recevons, ou pour
					protéger nos droits.
				</p>
				<p>PUBLICITÉ COMPORTEMENTALE</p>
				<p>
					Comme indiqué ci-dessus, nous utilisons vos Informations personnelles
					pour vous proposer des publicités ciblées ou des messages de marketing
					qui, selon nous, pourraient vous intéresser. Pour en savoir plus sur
					le fonctionnement de la publicité ciblée, vous pouvez consulter la
					page d'information de la Network Advertising Initiative (NAI) à
					l'adresse suivante :
					http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
				</p>
				<p>
					Vous pouvez refuser la publicité ciblée ici :<br /> QUELQUES LIENS
					COURANTS :<br /> FACEBOOK – https://www.facebook.com/settings/?tab=ads
					<br /> GOOGLE – https://www.google.com/settings/ads/anonymous
				</p>
				<p>
					En outre, vous pouvez refuser certains de ces services en vous rendant
					sur le portail de désactivation de Digital Advertising Alliance à
					l'adresse suivante :<br /> https://optout.aboutads.info/?c=3&lang=fr.
				</p>
				<p>
					NE PAS SUIVRE
					<br />
					Veuillez noter que nous ne modifions pas la collecte de données de
					notre Site et nos pratiques d'utilisation lorsque nous détectons un
					signal « Ne pas suivre » sur votre navigateur.
				</p>
				<p>
					VOS DROITS
					<br />
					Si vous êtes résident(e) européen(ne), vous disposez d'un droit
					d'accès aux informations personnelles que nous détenons à votre sujet
					et vous pouvez demander à ce qu'elles soient corrigées, mises à jour
					ou supprimées. Si vous souhaitez exercer ce droit, veuillez nous
					contacter au moyen des coordonnées précisées ci-dessous.
					<br />
					Par ailleurs, si vous êtes résident(e) européen(ne), notez que nous
					traitons vos informations dans le but de remplir nos obligations
					contractuelles à votre égard (par exemple si vous passez une commande
					sur le Site) ou de poursuivre nos intérêts commerciaux légitimes,
					énumérés ci-dessus. Veuillez également noter que vos informations
					seront transférées hors de l'Europe, y compris au Canada et aux
					États-Unis.
				</p>
				<p>
					RÉTENTION DES DONNÉES
					<br />
					Lorsque vous demandez un devis par l'intermédiaire du Site, nous
					conservons les Informations sur votre demande dans nos dossiers, sauf
					si et jusqu'à ce que vous nous demandiez de les supprimer.
				</p>
				<p>
					CHANGEMENTS
					<br />
					Nous pouvons être amenés à modifier la présente politique de
					confidentialité de temps à autre afin d'y refléter, par exemple, les
					changements apportés à nos pratiques ou pour d'autres motifs
					opérationnels, juridiques ou réglementaires.
					<br />
					NOUS CONTACTER
					<br />
					Pour en savoir plus sur nos pratiques de confidentialité, si vous avez
					des questions ou si vous souhaitez déposer une réclamation, veuillez
					nous contacter par e-mail à globaldigitaleco@gmail.com, ou par
					courrier à l'adresse suivante :
				</p>
				<p>259 Avenue du General LECLERC, Maison Alfort , 94700, France</p>
			</div>
		</>
	)
}

export default Confidentialite
