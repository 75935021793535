import Question from './Question'

const questions = [
	{
		id: 1,
		question: `Prime énergie: qu'est-ce que c'est ?`,
		reponse: "j'en ai aucune ideé",
	},
	{
		id: 2,
		question: `Qu’est-ce que la Loi Pope ?`,
		reponse:
			'En 2005, la loi POPE définit les engagements de la France en matière de transition énergétique. Elle crée le dispositif des Certificats d’Economie d’Energie (CEE), pour inciter les grands acteurs de l’énergie en France à entreprendre eux-mêmes ou encourager les particuliers à réaliser des économies d’énergie.',
	},
	{
		id: 3,
		question: `Comment ça fonctionne ?`,
		reponse: 'like hell I know',
	},
]

function FAQ() {
	return (
		<div className="flex column bg-whiteblue ">
			<div className="flex column center margin-top-2">
				<h1 className="no-margin color-secondary big-font">FAQ</h1>
				<h2 className="no-margin color-secondary medium-font">
					Questions et réponses les plus fréquentes
				</h2>
				{questions.map(question => (
					<Question data={question} key={question.id} />
				))}
			</div>
		</div>
	)
}

export default FAQ
