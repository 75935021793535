import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useWidth } from '../../Hooks'

function FormHeader({
	formData,
	setFormData,
	showErrorMessage,
	validateNumber,
	validateEmail,
	validateCodePostal,
}) {
	const [error, setError] = useState({
		email: false,
		code_postal: false,
		telephone: false,
	})
	const width = useWidth()
	const checkCodePostal = () => {
		if (!validateCodePostal(formData.code_postal)) {
			toast.error('Veuillez entrer un code postal valide')
			setError({
				...error,
				code_postal: true,
			})
		} else {
			setError({
				...error,
				code_postal: false,
			})
		}
	}
	const checkPhoneNumber = () => {
		if (!validateNumber(formData.telephone)) {
			toast.error('Veuillez entrer un numéro de téléphone valide')
			setError({
				...error,
				telephone: true,
			})
		} else {
			setError({
				...error,
				telephone: false,
			})
		}
	}
	const checkEmail = () => {
		if (!validateEmail(formData.email)) {
			toast.error('Veuillez entrer une adresse e-mail valide')
			setError({
				...error,
				email: true,
			})
		} else {
			setError({
				...error,
				email: false,
			})
		}
	}

	return (
		<div className="flex column width-80 center">
			<div className="header-devis">
				<h1 className="partenaire-title">Devenir Partenaire</h1>
			</div>

			<div className="form-container margin-0-auto flex  space-around">
				<div className="form-section">
					<div className="input-container">
						<label htmlFor="siret" className="label-text">
							Siret <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label ${
								showErrorMessage && !formData.siret && 'red-border'
							}`}
							placeholder="Siret"
							type="text"
							value={formData.siret}
							onChange={e =>
								setFormData({
									...formData,
									siret: e.target.value,
								})
							}
							id="siret"
						/>
					</div>
					<div className="input-container">
						<label htmlFor="prenom" className="label-text">
							Raison sociale <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label ${
								showErrorMessage && !formData.raison_sociale && 'red-border'
							}`}
							placeholder="Raison sociale"
							type="text"
							value={formData.raison_sociale}
							onChange={e =>
								setFormData({
									...formData,
									raison_sociale: e.target.value,
								})
							}
							id="prenom"
						/>
					</div>
				</div>
				<div className="form-section">
					<div className="input-container">
						<label htmlFor="E-mail" className="label-text">
							Adresse mail <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label ${
								((showErrorMessage && !formData.email) || error.email) &&
								'red-border'
							}`}
							placeholder="E-mail"
							type="email"
							id="E-mail"
							value={formData.email}
							onBlur={() => checkEmail()}
							onChange={e =>
								setFormData({
									...formData,
									email: e.target.value,
								})
							}
						/>
					</div>
					<div className="input-container">
						<label htmlFor="Telephone" className="label-text">
							Numéro de téléphone <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label ${
								((showErrorMessage && !formData.telephone) ||
									error.telephone) &&
								'red-border'
							}`}
							placeholder="ex : 0806852315"
							type="number"
							value={formData.telephone}
							onBlur={() => {
								checkPhoneNumber()
							}}
							onChange={e =>
								setFormData({
									...formData,
									telephone: e.target.value,
								})
							}
							id="Telephone"
						/>
					</div>
				</div>
				<div className={width > 1000 ? 'form-section ' : 'form-section rows'}>
					<div className="input-container input-container-fully">
						<label htmlFor="Adresse" className="label-text">
							Adresse <span className="color-primary">*</span>
						</label>
						<input
							className={`input-label width-adresse ${
								showErrorMessage && !formData.adresse && 'red-border'
							}`}
							placeholder="Adresse"
							type="text"
							value={formData.adresse}
							onChange={e =>
								setFormData({
									...formData,
									adresse: e.target.value,
								})
							}
							id="Adresse"
						/>
					</div>
					<div className="input-container">
						<div className="two-rows">
							<div className="input-container">
								<label htmlFor="ville" className="label-text">
									Ville <span className="color-primary">*</span>
								</label>
								<input
									className={`input-label ${
										showErrorMessage && !formData.ville && 'red-border'
									}`}
									placeholder="Ville"
									type="text"
									value={formData.ville}
									onChange={e =>
										setFormData({
											...formData,
											ville: e.target.value,
										})
									}
									id="ville"
								/>
							</div>
							<div className="input-container">
								<label htmlFor="CodePostal" className="label-text">
									Code Postal <span className="color-primary">*</span>
								</label>
								<input
									className={`input-label ${
										((showErrorMessage && !formData.code_postal) ||
											error.code_postal) &&
										'red-border'
									}`}
									placeholder="10000"
									type="number"
									onBlur={() => {
										checkCodePostal()
									}}
									value={formData.code_postal}
									onChange={e =>
										setFormData({
											...formData,
											code_postal: e.target.value,
										})
									}
									id="CodePostal"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FormHeader
