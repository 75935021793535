import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { star, verified } from '../../assets/icons'
import { useWidth } from '../../Hooks'

SwiperCore.use([Navigation, Pagination])

const data = [
	{
		id: 1,
		img: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg',
	},
	{
		id: 2,
		img: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg',
	},
	{
		id: 3,
		img: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg',
	},
	{
		id: 4,
		img: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg',
	},
]
const avis = [
	{
		id: 1,
		star: 5,
		commentaire: 'Excellent travail. Je recommande à tous',
		nom: 'Michel R.',
		img: 'https://as1.ftcdn.net/v2/jpg/03/29/38/50/1000_F_329385073_lbj3k0oJFprRoRXnWbm0eKTwY4NVYZJ9.jpg',
	},
	{
		id: 2,
		star: 5,
		commentaire: 'Excellent travail. Je recommande à tous',
		nom: 'Michel R.',
		img: 'https://as1.ftcdn.net/v2/jpg/03/29/38/50/1000_F_329385073_lbj3k0oJFprRoRXnWbm0eKTwY4NVYZJ9.jpg',
	},
]

const AvisWeb = ({ item }) => (
	<div className="avis">
		<div className="flex center">
			<img src={star} alt="etoile" />
			<img src={star} alt="etoile" />
			<img src={star} alt="etoile" />
			<img src={star} alt="etoile" />
			<img src={star} alt="etoile" />
		</div>
		<p className="commentaire"> {item.commentaire} </p>
		<div className="flex center">
			<img src={item.img} alt="avatar" className="avatar" />
			<h3>{item.nom} </h3>
		</div>
	</div>
)
const AvisPhone = ({ item }) => (
	<div className="avisPhone">
		<div className="flex center border">
			<img src={item.img} alt="avatar" className="avatar smart-img" />
		</div>
		<div className="blanco">
			<h3>{item.nom} </h3>
			<p className="commentaire"> {item.commentaire} </p>
			<div className="flex center margin-bottom-2">
				<img src={star} alt="etoile" className="star" />
				<img src={star} alt="etoile" className="star" />
				<img src={star} alt="etoile" className="star" />
				<img src={star} alt="etoile" className="star" />
				<img src={star} alt="etoile" className="star" />
			</div>
		</div>
	</div>
)

function Slider() {
	const width = useWidth()
	return (
		<div className="flex column bg-whiteblue ">
			<div className="flex column center margin-top-2">
				<h1 className="no-margin color-secondary big-font ">Nos Partenaires</h1>
				<hr className="border-Bottom" />
			</div>
			<Swiper
				slidesPerView={3}
				navigation
				loop={Infinity}
				pagination={{ clickable: true }}
				className="Custom-slider"
			>
				{data.map(item => (
					<SwiperSlide key={item.id}>
						<div className="slider-trois">
							<img className="img-slider" src={item.img} alt={item.id} />
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="flex column center margin-top-2">
				<h1 className="no-margin color-secondary big-font ">
					Ils nous font confiance !
				</h1>
				<div className="flex center">
					<img src={verified} alt="verifié" className="verified" />
					<h2 className="no-margin color-secondary medium-font">
						Avis vérifiés
					</h2>
				</div>
			</div>
			<Swiper
				slidesPerView={1}
				navigation
				loop={Infinity}
				pagination={{ clickable: false }}
				className={
					width > 600
						? 'Custom-slider  margin-top-2'
						: 'Custom-slider-phone margin-top-2'
				}
			>
				{avis.map(item => (
					<SwiperSlide key={item.id}>
						{width > 600 ? <AvisWeb item={item} /> : <AvisPhone item={item} />}
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

export default Slider
