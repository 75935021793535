import {
	facebook,
	instagram,
	linkedin,
	phone,
	phoneWhite,
	whatsapp,
	youtube,
} from '../../assets/icons'
import { femme } from '../../assets/images'
import { useWidth } from '../../Hooks'

function Banner() {
	const width = useWidth()
	return (
		<div className="contact-container ">
			{width > 1000 && <img src={femme} alt="femme" className="femme-image" />}
			<div className="contact-content">
				<h1>Contactez-nous !</h1>
				<div className="contact-buttons">
					<button className="contact-button-left">
						<img src={phone} alt="phone" />
						<div className="contact-button-content">
							<h4>Pour Particulier</h4>
							<h5>01 43 96 75 19</h5>
						</div>
					</button>
					<button className="contact-button-right">
						<img src={phoneWhite} alt="phone" />
						<div className="contact-button-content">
							<h4>Pour RGE</h4>
							<h5>01 43 96 75 18</h5>
						</div>
					</button>
				</div>
				<div className="contact-social-media">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.facebook.com/Globaldigitalecology/"
					>
						<img src={facebook} alt="facebook" />
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.instagram.com/global_digital_ecology/"
					>
						<img src={instagram} alt="instagram" />
					</a>
					{/* <a href="">
						<img src={whatsapp} alt="whatsapp" />
					</a> */}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.youtube.com/channel/UCW1nFRYfabcvlf0Y8CL7loA"
					>
						<img src={youtube} alt="youtube" />
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.linkedin.com/company/global-digital-ecology"
					>
						<img src={linkedin} alt="linkedin" />
					</a>
				</div>
			</div>
		</div>
	)
}

export default Banner
