import React from 'react'
import { femme } from '../../assets/images'
import { phone } from '../../assets/icons'
import { useWidth } from '../../Hooks'

function SolutionsFooter() {
	const width = useWidth()
	return (
		<div className="footer-solutions-container">
			{width > 700 && <img src={femme} alt="femme" className="img-femme" />}
			<div className="footer-info">
				<h1>Contactez-nous !</h1>
				<p>
					Vous avez un projet de travaux ou des interrogations ? <br />
					Nous sommes là pour vous répondre. <br />
					Nos experts travaux vous accompagnent tout au long de votre projet,
					n'hésitez pas à nous appeler.
				</p>
				<div className="info-timing">
					<div className="phone-footer">
						<img src={phone} alt="phone" />
						<h1>0143967519</h1>
					</div>
					<p>Du lundi au vendredi de 9h à 19h30</p>
				</div>
			</div>
		</div>
	)
}

export default SolutionsFooter
