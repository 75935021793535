import React, { useState, useEffect } from 'react'
import { closeNav, ok } from '../../../assets/icons'

function IsolationModel({
	openModels,
	setOpenModels,
	reset,
	setReset,
	addBesoinObjet,
}) {
	const [options, setOptions] = useState({
		nom: 'Isolation',
		isolation_des_combles: '',
		isolation_des_murs: '',
		isolation_garage: false,
		isolation_cave: false,
		isolation_vide_sanitaire: false,
	})

	useEffect(() => {
		if (reset) {
			setReset(false)
			setOptions({
				nom: 'Isolation',
				isolation_des_combles: '',
				isolation_des_murs: '',
				isolation_garage: false,
				isolation_cave: false,
				isolation_vide_sanitaire: false,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reset])
	useEffect(() => {
		if (openModels.IsolationModelIsOpen) document.body.style.overflow = 'hidden'
		else document.body.style.overflow = 'unset'
	}, [openModels.IsolationModelIsOpen])

	if (!openModels.IsolationModelIsOpen) {
		return null
	}

	return (
		<>
			<div
				className="overlay"
				onClick={() =>
					setOpenModels({ ...openModels, IsolationModelIsOpen: false })
				}
			/>
			<div className="modal">
				<div className="headerNavigation">
					<button className="all100p" type="button">
						<img
							src={ok}
							alt="fermer la navigation"
							className="close-Model"
							onClick={() => {
								addBesoinObjet(options)
								setOpenModels({ ...openModels, IsolationModelIsOpen: false })
							}}
						/>
					</button>
					<button type="button" className="all100p">
						<img
							src={closeNav}
							alt="fermer la navigation"
							className="close-Model"
							onClick={() =>
								setOpenModels({ ...openModels, IsolationModelIsOpen: false })
							}
						/>
					</button>
				</div>
				<label className="label-text margin-top-1rem">Type d’isolation ?</label>
				<div className="card-form-container margin-bottom-1rem margin-top-05rem">
					<button
						type="button"
						className={
							options.isolation_des_combles
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_des_combles: !options.isolation_des_combles,
							})
						}}
					>
						Isolation des combles
					</button>
					<button
						className={
							options.isolation_des_murs
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_des_murs: !options.isolation_des_murs,
							})
						}}
						type="button"
					>
						Isolation des murs
					</button>
					<button
						className={
							options.isolation_garage
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_garage: !options.isolation_garage,
							})
						}}
						type="button"
					>
						Isolation Garage
					</button>
					<button
						className={
							options.isolation_cave
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_cave: !options.isolation_cave,
							})
						}}
						type="button"
					>
						Isolation d’une cave
					</button>
					<button
						className={
							options.isolation_vide_sanitaire
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big'
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_vide_sanitaire: !options.isolation_vide_sanitaire,
							})
						}}
						type="button"
					>
						Isolation vide sanitaire
					</button>
				</div>

				<label className="label-text">
					Isolation des combles perdus ou amenagés ?
				</label>
				<div className="card-form-container justify-with-gap margin-bottom-1rem margin-top-05rem">
					<button
						className={
							options.isolation_des_combles === 'Perdus'
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big '
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_des_combles: 'Perdus',
							})
						}}
						type="button"
					>
						Isolation des combles perdus
					</button>
					<button
						className={
							options.isolation_des_combles === 'Amenagés'
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big '
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_des_combles: 'Amenagés',
							})
						}}
						type="button"
					>
						Isolation des combles amenagés
					</button>
				</div>

				<label className="label-text margin-top-1rem">
					Quels type d’aménagement des murs ?
				</label>
				<div className="card-form-container justify-with-gap margin-top-05rem">
					<div
						className={
							options.isolation_des_murs === 'Etérieur'
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big '
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_des_murs: 'Etérieur',
							})
						}}
					>
						Extérieur
					</div>
					<div
						className={
							options.isolation_des_murs === 'Intérieur'
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big '
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_des_murs: 'Intérieur',
							})
						}}
					>
						Intérieur
					</div>
					<div
						className={
							options.isolation_des_murs === 'Autre'
								? 'card-form width-card height-9rem big bg-linear color-white'
								: 'card-form width-card height-9rem big '
						}
						onClick={() => {
							setOptions({
								...options,
								isolation_des_murs: 'Autre',
							})
						}}
					>
						Autre ?
					</div>
				</div>
			</div>
		</>
	)
}

export default IsolationModel
