import React, { useState, useEffect } from 'react'
import {
	Besoins,
	DevisForm,
	InfosPersonnes,
	FooterForm,
} from '../components/devis'
import { v4 as uuidv4 } from 'uuid'
import { demanderDevis } from '../store/actions/guestActions'
import { useDispatch } from 'react-redux'
import '../styles/Devis.css'
import { useLocation } from 'react-router-dom'
import { useWidth } from '../Hooks'
import { toast } from 'react-toastify'

function Devis() {
	const width = useWidth()
	const [checked, setChecked] = useState(false)
	const [formData, setFormData] = useState({
		nom: '',
		prenom: '',
		email: '',
		telephone: '',
		adresse: '',
		ville: '',
		code_postal: '',
	})
	const [reset, setReset] = useState(false)
	const [maisonData, setMaisonData] = useState({
		nb_personnes_foyer: '',
		type_logement: '',
		anciennete_logement: '',
	})
	const [besoinsData, setBesoinsData] = useState({
		besoins: [],
	})
	const [FooterData, setFooterData] = useState({
		surface_logement: '',
		tranche_revenu_fiscal: '',
	})
	const [showErrorMessage, setShowErrorMessage] = useState(false)
	const dispatch = useDispatch()

	const { pathname } = useLocation()
	useEffect(() => {
		window.scrollTo({
			top: 0,
		})
	}, [pathname])

	const ValidForm = () => {
		let valid = true
		if (!checked) valid = false
		for (const property in formData) {
			if (property === 'email')
				(formData[property].includes('@') &&
					formData[property].includes('.')) ||
					(valid = false)

			if (property === 'code_postal')
				formData[property].length !== 5 && (valid = false)
			if (property === 'numero_telephone')
				formData[property].length !== 10 && (valid = false)
			else if (!formData[property]) valid = false
		}
		for (const property in maisonData) {
			if (!maisonData[property]) valid = false
		}

		return valid
	}
	//form handeling
	const submit = e => {
		e.preventDefault()
		if (!ValidForm()) {
			toast.error('Veuiller remplir le formulaire')
			setShowErrorMessage(true)
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
			return
		}
		setReset(true)
		//collecter la data
		const data = {
			id: uuidv4(),
			...formData,
			...maisonData,
			...besoinsData,
			...FooterData,
		}
		//envoyer la requete au serveur
		dispatch(demanderDevis(data))

		//afficher une notification de success
		toast.success('Votre demande a été envoyé')

		// vider le formulaire
		setFormData({
			nom: '',
			prenom: '',
			email: '',
			telephone: '',
			adresse: '',
			ville: '',
			code_postal: '',
		})
		setMaisonData({
			nb_personnes_foyer: '',
			type_logement: '',
			anciennete_logement: '',
		})
		setBesoinsData({
			besoins: [],
		})
		setFooterData({
			surface_logement: '',
			tranche_revenu_fiscal: '',
		})
	}
	return (
		<form onSubmit={e => submit(e)}>
			{width < 1000 && width > 600 && <div className="padding-top"></div>}
			<DevisForm
				formData={formData}
				showErrorMessage={showErrorMessage}
				setFormData={setFormData}
			/>
			<InfosPersonnes
				maisonData={maisonData}
				setMaisonData={setMaisonData}
				showErrorMessage={showErrorMessage}
			/>
			<Besoins
				besoinsData={besoinsData}
				setReset={setReset}
				reset={reset}
				setBesoinsData={setBesoinsData}
			/>
			<FooterForm
				FooterData={FooterData}
				setFooterData={setFooterData}
				checked={checked}
				setChecked={setChecked}
				showErrorMessage={showErrorMessage}
				email={formData.email}
			/>
		</form>
	)
}

export default Devis
