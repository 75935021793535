import React from 'react'
import { useWidth } from '../../Hooks'

const missions = [
	{
		id: 1,
		numMission: 1,
		title: 'Lorem ipsum',
		content: `Notre principale mission est de soutenir tous les acteurs qui participent à la
			transition écologique. Ensemble nous souhaitons agir pour un futur plus respectueux
			de l’environnement. Pour ça nous vous accompagnons tout le long de cette grande
			aventure grâce à des services pensés par vous et pour vous.`,
	},
	{
		id: 2,
		numMission: 2,
		title: 'Lorem ipsum',
		content: `Global Digital Ecology accompagne les professionnels et artisans à chaque étape
			de leur développement avec des services dédiés comme la gestion administrative
			des dossiers client, l’accompagnement commercial et bien plus encore à retrouver
			sur notre page « nos solutions ».`,
	},
	{
		id: 3,
		numMission: 3,
		title: 'Lorem ipsum',
		content: `L’accompagnement des particuliers tout au long de leurs projets d’éco-rénovation
			est l’une des principales missions de Global Digital Ecology. Nous avons mis en
			place plusieurs services pour faciliter vos démarches comme la mise en relation avec
			nos artisans partenaires RGE, l’assistance à l’obtention de vos aides, le prêt à taux
			0% et bien d’autres.`,
	},
	{
		id: 4,
		numMission: 4,
		title: 'Lorem ipsum',
		content: `Toute les équipes de Global Digital Ecology s’applique sur la qualité des services
			proposés, la satisfaction des clients et de nos partenaires. C’est pourquoi la gestion
			et le suivi des dossiers ainsi l’accompagnement personnalisé sont une priorité pour
			toute nos équipes.`,
	},
]

const MissionCard = ({ mission: { numMission, title, content } }) => (
	<div className="Mission-card flex column center">
		<div className="num-mission bg-linear color-white">{numMission}</div>
		{/* <span className="title-mission">{title}</span> */}
		<p className="content-mission">{content}</p>
	</div>
)

function Mission() {
	const width = useWidth()
	return (
		<div className="flex column bg-whiteblue">
			<div className="flex column center margin-top-2">
				<h1 className="no-margin color-secondary big-font ">Nos missions</h1>
				<hr className="border-Bottom" />
			</div>
			<div
				className={
					width > 1000
						? 'mission-cards flex space-around '
						: 'mission-cards flex space-around column'
				}
			>
				{missions.map(mission => (
					<MissionCard mission={mission} key={mission.id} />
				))}
			</div>
		</div>
	)
}

export default Mission
