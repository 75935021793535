import {
	LOGIN,
	START_REQUEST,
	END_REQUEST,
	LOGOUT,
	AUTH_ERROR,
	VERIFY_SESSION,
	NEW_PASS,
	PASS_CHANGED,
} from "./TYPES"
import Pool from "../../UserPool"
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js"

export const Login = payload => {
	return async dispatch => {
		try {
			dispatch({
				type: START_REQUEST,
			})

			const user = new CognitoUser({
				Username: payload.Username,
				Pool,
			})
			// user.setAuthenticationFlowType('USER_PASSWORD_AUTH')
			const authDetails = new AuthenticationDetails({
				Username: payload.Username,
				Password: payload.Password,
			})
			user.authenticateUser(authDetails, {
				onSuccess: data => {
					console.log(data)
					dispatch({
						type: LOGIN,
						payload: data,
					})
				},
				onFailure: err => {
					console.log(err)
					dispatch({
						type: AUTH_ERROR,
					})
				},
				// mfaRequired: function (codeDeliveryDetails) {
				// 	// MFA is required to complete user authentication.
				// 	// Get the code from user and call
				// 	user.sendMFACode(codeDeliveryDetails, this)
				// },
				newPasswordRequired: function (userAttributes, requiredAttributes) {
					// the api doesn't accept this field back
					if (payload.newPassword === undefined) {
						dispatch({
							type: NEW_PASS,
							payload: {
								email: userAttributes.email,
								password: payload.Password,
							},
						})
					} else {
						try {
							delete userAttributes.email
							user.completeNewPasswordChallenge(
								payload.newPassword,
								userAttributes
							)
							dispatch({
								type: PASS_CHANGED,
							})
						} catch (err) {
							console.log("else ", err)
						}
					}
				},
			})
			dispatch({
				type: END_REQUEST,
			})
		} catch (err) {
			console.log("login", err)
		}
	}
}
export const getUserSession = () => {
	return async dispatch => {
		try {
			//get session
			let cognitoUser = Pool.getCurrentUser()
			if (cognitoUser) {
				cognitoUser.getSession(function (err, session) {
					if (err) {
						// console.error(err.message || JSON.stringify(err))
						return
					} else if (session.isValid()) {
						dispatch({
							type: VERIFY_SESSION,
							payload: session,
						})
					} else
						dispatch({
							type: LOGOUT,
						})
				})
			}
		} catch (err) {
			console.log(err)
		}
	}
}
