import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { upload, uploadWhite, uploadCheck } from '../../assets/devisIcons'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFile } from '../../store/actions/guestActions'

function Assurance({
	assurance,
	setAssurance,
	showErrorMessage,
	showErrorNotification,
	siret,
}) {
	const { progress } = useSelector(state => state.guest)
	const dispatch = useDispatch()
	const onDrop = useCallback(
		acceptedFiles => {
			if (siret) {
				const type = acceptedFiles[0]?.name
					.substring(acceptedFiles[0]?.name.indexOf('.'))
					.toLowerCase()

				//rename the file
				const file = new File([acceptedFiles[0]], `assurance_decennale${type}`)

				//send to server
				dispatch(uploadFile({ file, siret, fileName: file.name }))
				setAssurance({
					alias: 'assurance_decennale',
					nom: `${siret}_assurance_decennale${type}`,
				})
			} else showErrorNotification()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[siret]
	)
	const { getInputProps, getRootProps } = useDropzone({
		onDrop,
		multiple: false,
		maxSize: 5242880,
		disabled: progress === 0 ? false : true,
		accept: '.pdf, image/*',
	})
	return (
		<div {...getRootProps()}>
			<label htmlFor="assurance" className="label-text">
				Glissez & Déposez votre attestation Assurance décennale{' '}
				<span className="color-primary">*</span>
			</label>
			<input id="assurance" {...getInputProps()} />
			<div
				className={`upload-item ${assurance && 'accepted-upload'} ${
					showErrorMessage && !assurance && 'red-border'
				} ${progress > 0 && 'disabled'} `}
			>
				<img src={assurance ? uploadWhite : upload} alt="upload icon" />
				Déposez votre Assurance décennale
				{assurance && (
					<img src={uploadCheck} alt="upload icon" className="upload-check" />
				)}
			</div>
		</div>
	)
}

export default Assurance
