import { Link } from 'react-router-dom'

function PostFooter() {
	return (
		<div className="flex column center height-30r  bg-white">
			<div>
				<h1 className="no-margin big-font color-secondary">
					GLOBAL DIGITAL ECOLOGY
				</h1>
				<h2 className="no-margin medium-font font-weight-500 color-secondary">
					ACCOMPAGNATEUR DE LA TRANSITION ÉCOLOGIQUE
				</h2>
			</div>
			<div className="Banner-buttons center-content">
				<Link
					to="/devis"
					style={{ textDecoration: 'none' }}
					className="button flex center banner-button"
				>
					Demander des devis
				</Link>
				<Link
					to="/devenir-partenaire"
					style={{ textDecoration: 'none' }}
					className="button-secondary flex center banner-button"
				>
					Devenir Partenaire
				</Link>
			</div>
		</div>
	)
}

export default PostFooter
