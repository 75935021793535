export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const AUTH_ERROR = 'AUTH_ERROR'
export const NEW_PASS = 'NEW_PASS'
export const VERIFY_SESSION = 'VERIFY_SESSION'
export const PASS_CHANGED = 'PASS_CHANGED'

export const PROGRESS = 'PROGRESS'
export const END_PROGRESS = 'END_PROGRESS'
export const START_PROGRESS = 'START_PROGRESS'

export const START_REQUEST = 'START_REQUEST'
export const END_REQUEST = 'END_REQUEST'
export const DEMANDER_DEVIS = 'DEMANDER_DEVIS'
