import React from 'react'
import { PhoneTopRight } from '../components/shared'
import { useWidth } from '../Hooks'
import { Banner, Form } from '../components/contact'
import '../styles/contact.css'

function Contact() {
	const width = useWidth()
	return (
		<>
			{width < 1000 && width > 600 && <div className="padding-top"></div>}
			<PhoneTopRight />
			<div className="img-background-contact">
				<h1> global digital ecology </h1>
			</div>
			<Banner />
			<Form />
		</>
	)
}

export default Contact
