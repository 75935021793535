import * as actionTypes from '../actions/TYPES'

const initialState = {
	loading: false,
	progress: 0,
}

const guestReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.START_REQUEST: {
			return {
				...state,
				loading: true,
			}
		}
		case actionTypes.END_REQUEST: {
			return {
				...state,
				loading: false,
			}
		}
		case actionTypes.PROGRESS: {
			return {
				...state,
				progress: action.progress,
			}
		}
		case actionTypes.START_PROGRESS: {
			return {
				...state,
				progress: 1,
			}
		}
		case actionTypes.END_PROGRESS: {
			return {
				...state,
				progress: 0,
			}
		}

		default:
			return { ...state }
	}
}
export default guestReducer
