import {
	START_REQUEST,
	END_REQUEST,
	PROGRESS,
	END_PROGRESS,
	START_PROGRESS,
} from './TYPES'
import axios from 'axios'
import moment from 'moment'

export const demanderDevis = payload => {
	const api = axios.create({
		baseURL: `${process.env.REACT_APP_URL_API}`,
	})
	return async dispatch => {
		try {
			dispatch({
				type: START_REQUEST,
			})

			await api.post('/devis', JSON.stringify(payload))
			dispatch({
				type: END_REQUEST,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const getUserIp = email => {
	return async dispatch => {
		try {
			//get user Ip
			const userIp = await axios.get('https://api.ipify.org/?format=json')
			//get the current date
			const dateConsentement =
				moment(Date.now()).format('YYYY-MM-DDTHH:MM:SS') + 'Z'
			//collect the data
			const data = JSON.stringify({
				adresseIp: userIp.data.ip,
				dateConsentement,
				typeDemande: 'devis',
				email,
			})

			//send to backend
			await axios.post(process.env.REACT_APP_API_COLLECT_DATA_URL, data)
		} catch (err) {
			console.error(err)
		}
	}
}

export const submitPartenaire = data => {
	return async dispatch => {
		try {
			await axios.post(process.env.REACT_APP_DEVENIR_PARTENAIRE, data)
		} catch (err) {
			console.error(err)
		}
	}
}

export const uploadFile = payload => {
	const { file, siret, fileName } = payload

	return async dispatch => {
		try {
			//start request
			dispatch({
				type: START_REQUEST,
			})
			dispatch({
				type: START_PROGRESS,
			})
			//start upload
			const url = await axios.get(
				`${process.env.REACT_APP_UPLOAD_FILES}filename=${fileName}&siret=${siret}`
			)
			// create a form obj
			const formData = new FormData()

			// append the fields in presignedPostData in formData
			Object.keys(url.data.fields).forEach(key => {
				formData.append(key, url.data.fields[key])
			})

			// // append the file
			formData.append('file', file)
			// // post the data on the s3 url
			await axios.post(url.data.url, formData, {
				headers: {
					'Content-Type': 'application/octet-stream',
					'x-amz-acl': 'public-read',
				},
				onUploadProgress: progressEvent => {
					const { loaded, total } = progressEvent
					let progress = Math.floor((loaded * 100) / total)
					dispatch({
						type: PROGRESS,
						progress,
					})
				},
			})

			//end request
			dispatch({
				type: END_PROGRESS,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const sendContact = payload => {
	const { email, message, prenom, nom } = payload
	const data = {
		airTableName: 'Messages',
		data: {
			records: [
				{
					fields: {
						Email: email,
						Message: message,
						Status: 'Nouveau',
						Prenom: prenom,
						Nom: nom,
						'Date reception': new Date().toISOString(),
					},
				},
			],
		},
	}
	return async () => {
		try {
			await axios.post(process.env.REACT_APP_CONTACT_API, JSON.stringify(data))
		} catch (err) {
			console.error(err)
		}
	}
}
