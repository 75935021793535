import { useState } from 'react'
import {
	appartement,
	prime,
	co,
	ventillation,
	air,
	renovation,
	energie,
	chauffage,
	energieSolaire,
} from '../../assets/devisIcons'
import SolutionModal from './SolutionModal'

function Services() {
	const [section, setSection] = useState('')
	const [openModal, setOpenModal] = useState(false)

	const handleClick = section => {
		setSection(section)
		setOpenModal(true)
	}

	return (
		<div className="flex column width-80">
			<h1 className="service-title">Découvrez nos services</h1>
			<div className="card-form-container wrap margin-top-05rem">
				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#isolation')}
				>
					<img src={appartement} alt="Isolation" className="home-icon" on />
					Isolation
				</button>
				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#renovation')}
				>
					<img src={renovation} alt="Rénovation" className="home-icon" />
					Rénovation
				</button>
				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#bilan')}
				>
					<img src={energie} alt="Bilan Énérgetique" className="home-icon" />
					Bilan Énérgetique
				</button>
				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#chauffage')}
				>
					<img src={chauffage} alt="Chauffage" className="home-icon" />
					Chauffage
				</button>
				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#energieSolaire')}
				>
					<img
						src={energieSolaire}
						alt="Énérgie solaire"
						className="home-icon"
					/>
					Énérgie solaire
				</button>
				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#ventillation')}
				>
					<img src={ventillation} alt="Ventillation" className="home-icon" />
					Ventillation
				</button>

				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#air')}
				>
					<img src={air} alt="air" className="home-icon" />
					Installation de purificateur d’air
				</button>

				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#co')}
				>
					<img src={co} alt="co" className="home-icon" />
					Installation de détecteur CO2
				</button>
				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#borne')}
				>
					<img src={appartement} alt="Bome de recharge" className="home-icon" />
					Installation de Borne de recharge
				</button>
				<button
					className="card-form margin-top-1rem width-17p height-9rem big column"
					type="button"
					onClick={() => handleClick('#prime')}
				>
					<img src={prime} alt="prime" className="home-icon" />
					Combien ma prime ?
				</button>
			</div>
			{openModal && (
				<SolutionModal setOpenModal={setOpenModal} section={section} />
			)}
		</div>
	)
}

export default Services
