import {
	HeaderSolutions,
	Services,
	SolutionsFooter,
} from '../components/solutions'
import '../styles/solutions.css'
import { useWidth } from '../Hooks'
import { Email, PhoneTopRight } from '../components/shared'
function Solutions() {
	const width = useWidth()
	return (
		<>
			{width < 1000 && width > 600 && <div className="padding-top"></div>}
			<PhoneTopRight />
			<HeaderSolutions />
			<Services />
			<Email />
			<SolutionsFooter />
		</>
	)
}

export default Solutions
