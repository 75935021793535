import React from "react"
import { phone, phoneWhite } from "../../assets/icons"

function PhoneTopRight() {
	return (
		<div className="new-top-right">
			<h3>Contactez-nous !</h3>
			<div className="flex small-full gap-small">
				<div className="new-buttons-header btn-secondary">
					<img src={phone} alt="phone" />
					<div>
						<h4>Particulier</h4>
						<h5>01 80 91 30 15</h5>
					</div>
				</div>
				<div className="new-buttons-header btn-primary">
					<img src={phoneWhite} alt="phone" />
					<div>
						<h4>RGE</h4>
						<h5>01 80 91 30 15</h5>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PhoneTopRight
