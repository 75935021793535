import React from 'react'
import {
	yes,
	no,
	yesWhite,
	noWhite,
	maisonWhite,
	maison,
	appartementWhite,
	appartement,
} from '../../assets/devisIcons'

function InfosPersonnes({ maisonData, setMaisonData, showErrorMessage }) {
	return (
		<div className="flex column width-80">
			<label className="label-text">
				Nombre de personnes au foyer <span className="color-primary">*</span>
			</label>
			<div className="card-form-container margin-top-05rem">
				<button
					className={
						maisonData.nb_personnes_foyer === '1'
							? 'card-form medium bg-linear color-white'
							: 'card-form medium'
					}
					type="button"
					onClick={() =>
						setMaisonData({
							...maisonData,
							nb_personnes_foyer: '1',
						})
					}
				>
					1
				</button>
				<button
					className={
						maisonData.nb_personnes_foyer === '2'
							? 'card-form medium bg-linear color-white'
							: 'card-form medium'
					}
					onClick={() =>
						setMaisonData({
							...maisonData,
							nb_personnes_foyer: '2',
						})
					}
					type="button"
				>
					2
				</button>
				<button
					className={
						maisonData.nb_personnes_foyer === '3'
							? 'card-form medium bg-linear color-white'
							: 'card-form medium'
					}
					onClick={() =>
						setMaisonData({
							...maisonData,
							nb_personnes_foyer: '3',
						})
					}
					type="button"
				>
					3
				</button>
				<button
					className={
						maisonData.nb_personnes_foyer === '4'
							? 'card-form medium bg-linear color-white'
							: 'card-form medium'
					}
					onClick={() =>
						setMaisonData({
							...maisonData,
							nb_personnes_foyer: '4',
						})
					}
					type="button"
				>
					4
				</button>
				<button
					className={
						maisonData.nb_personnes_foyer === 'autre'
							? 'card-form medium bg-linear color-white'
							: 'card-form medium'
					}
					onClick={() =>
						setMaisonData({
							...maisonData,
							nb_personnes_foyer: 'autre',
						})
					}
					type="button"
				>
					Autre
				</button>
			</div>
			{showErrorMessage && !maisonData.nb_personnes_foyer && (
				<label className="label-text color-red margin-top-05rem">
					Veuillez choisir un choix
				</label>
			)}
			<label className="label-text margin-top-1rem">
				Type du logement <span className="color-primary">*</span>
			</label>
			<div className="card-form-container margin-top-05rem">
				<button
					className={
						maisonData.type_logement === 'Maison'
							? 'card-form width-45p big column bg-linear color-white'
							: 'card-form width-45p big column'
					}
					onClick={() =>
						setMaisonData({
							...maisonData,
							type_logement: 'Maison',
						})
					}
					type="button"
				>
					<img
						src={maisonData.type_logement === 'Maison' ? maisonWhite : maison}
						alt="maison"
						className="home-icon"
					/>
					Maison
				</button>
				<button
					className={
						maisonData.type_logement === 'Appartement'
							? 'card-form width-45p big column bg-linear color-white'
							: 'card-form width-45p big column'
					}
					onClick={() =>
						setMaisonData({
							...maisonData,
							type_logement: 'Appartement',
						})
					}
					type="button"
				>
					<img
						src={
							maisonData.type_logement === 'Appartement'
								? appartementWhite
								: appartement
						}
						alt="appartement"
						className="home-icon"
					/>
					Appartement
				</button>
			</div>

			{showErrorMessage && !maisonData.type_logement && (
				<label className="label-text color-red margin-top-05rem">
					Veuillez choisir un choix
				</label>
			)}
			<label className="label-text margin-top-1rem">
				Votre logement à plus de 2 ans ?
			</label>
			<div className="card-form-container margin-top-05rem">
				<button
					className={
						maisonData.anciennete_logement === 'Oui'
							? 'card-form width-45p medium bg-linear color-white'
							: 'card-form width-45p medium'
					}
					onClick={() =>
						setMaisonData({
							...maisonData,
							anciennete_logement: 'Oui',
						})
					}
					type="button"
				>
					<img
						src={maisonData.anciennete_logement === 'Oui' ? yesWhite : yes}
						alt="yes"
						className="yes-no-icon"
					/>
					OUI
				</button>
				<button
					className={
						maisonData.anciennete_logement === 'Non'
							? 'card-form width-45p medium bg-linear color-white'
							: 'card-form width-45p medium'
					}
					onClick={() =>
						setMaisonData({
							...maisonData,
							anciennete_logement: 'Non',
						})
					}
					type="button"
				>
					<img
						src={maisonData.anciennete_logement === 'Non' ? noWhite : no}
						alt="no"
						className="yes-no-icon"
					/>
					NON
				</button>
			</div>
		</div>
	)
}

export default InfosPersonnes
