import { useEffect } from 'react'
import { closeNav } from '../../assets/icons'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

function SolutionModal({ setOpenModal, section }) {
	useEffect(() => {
		document.body.style.overflow = 'hidden'
		gsap.to('.modal', { duration: 1, scrollTo: section })
		return () => {
			document.body.style.overflow = 'unset'
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<div className="overlay" onClick={() => setOpenModal(false)} />
			<div className="modal modal-solutions">
				<div className="headerNavigation">
					<img
						src={closeNav}
						alt="fermer la navigation"
						className="close-Model"
						onClick={() => setOpenModal(false)}
					/>
				</div>
				<p id="bilan">
					<strong>Bilan énergétique </strong>: Nos experts réalisent le bilan
					thermique et volumétrique de votre logement pour trouver les solutions
					adaptées à votre domicile.
				</p>
				<p id="isolation">
					<strong>Isolation </strong>: Nos experts réalisent le bilan thermique
					et volumétrique de votre logement pour trouver les solutions adaptées
					à votre domicile.
				</p>
				<p>
					<strong>Isolation des combles </strong>: Isoler les combles va
					permettre de limiter les déperditions de chaleur les plus importantes
					de votre logement. L’air chaud s’élève naturellement vers les étages
					et s’échappe par la toiture. Une bonne isolation des combles permet
					une réduction de la facture d’énergie pouvant aller jusqu’à 30%.
				</p>
				<p>
					<strong>Isolation de murs extérieurs </strong>: L’isolation des murs
					par l’extérieur contribue à réduire votre facture énergétique,
					améliorer votre confort et donner un nouveaux look à votre logement.
					Elle agit comme un manteau sur votre habitation et permet de supprimer
					les ponts thermiques, réguler l’humidité de votre logement et protège
					votre façade des intempéries.
				</p>
				<p>
					<strong>Isolation des murs intérieurs </strong>: L’isolation
					intérieure de vos murs va permettre de remédier à la majeure partie
					des problèmes de déperdition de chaleur de votre logement. En effet
					environ 25% des déperditions de chaleur de votre foyer sont dues à des
					murs mal isolés. L’isolation des murs par l’intérieur vous assure
					l’amélioration du confort thermique et acoustique de votre logement.
				</p>
				<p>
					<strong>Isolation plancher bas </strong>: Une meilleure isolation du
					plancher bas permet à votre habitation de plus facilement garder la
					chaleur l’hiver et le frais l’été. Isoler est la meilleure façon
					d’améliorer votre confort thermique, c’est aussi un moyen de faire
					baisser sa facture d’énergie et de réduire ses émissions de gaz à
					effet de serre.
				</p>
				<p id="renovation">
					<strong>Rénovation </strong>: La rénovation énergétique comprend tous
					les types de travaux qui servent à réduire la consommation d’énergie
					de votre logement comme l’isolation, l’installation d’une pompe à
					chaleur ou d’une VMC. La rénovation énergétique a plusieurs grands
					avantages tels que :
					<ul>
						<li>
							La réduction de vos factures d’énergie notamment sur le chauffage
						</li>
						<li>L’amélioration du confort de votre logement</li>
						<li>
							La protection de l’environnement par la réduction des émissions de
							gaz à effet de serre
						</li>
						<li>La valorisation de votre patrimoine immobilier</li>
					</ul>
				</p>
				<p id="chauffage">
					<strong>Chauffage </strong>: Votre système de chauffage est un élément
					important et nécessaire au confort de votre logement. Nos partenaires
					RGE recommandent d’avoir un système de chauffage dit performant tel
					que les pompes à chaleur ou les chaudières à condensation pour
					garantir le confort thermique de votre habitation et réduire vos
					charges énergétiques.
				</p>
				<p>
					<strong>Pompe à chaleur </strong>: La pompe à chaleur a de nombreux
					atouts, elle permet de réduire vos factures d’énergie, d’améliorer
					votre confort de chauffe et elle est très simple à utiliser. En effet,
					elle n’a pas besoin d’espace de stockage pour le combustible et peut
					produire le chauffage et l’eau chaude sanitaire, de plus sa
					consommation est reconnue pour être très faible.
				</p>
				<p>
					<strong>Chauffe-eau thermodynamique </strong>: Le chauffe-eau
					thermodynamique est l’un des systèmes de production d’eau chaude les
					plus économiques qui soit. En effet il consomme 3 fois moins d’énergie
					qu’un chauffe-eau électrique classique, il est alimenté par l’air
					ambiant ou l’air extérieur de votre logement. Son prix est très
					accessible (environ 2000€) et il est très vite rentabilisé.
				</p>
				<p>
					<strong>Chaudière à gaz </strong>: La chaudière à gaz est très
					intéressante elle sait s’adapter à beaucoup de situations et elle
					présente très peu d’inconvénients. Elle peut utiliser plusieurs
					technologies : classique, basse température ou à condensation. La
					chaudière au gaz peut fonctionner avec du gaz naturel ou avec du gaz
					propane, un premier avantage qui vous laissera une grande flexibilité
					au niveau des choix de l’énergie utilisée. La chaudière au gaz naturel
					est la plus pratique car vous n’avez pas besoin de stocker de
					combustible. Elle est peu volumineuse, facile à installer et à
					utiliser.
				</p>
				<p>
					<strong>Chauffage d’appoint </strong>: Le chauffage d’appoint
					fonctionne indépendamment du système central de chauffage, il peut
					servir en cas de vague de froid. Il est très simple à installer et
					permet de chauffer rapidement et de façon ponctuelle des pièces
					rarement occupées. Il existe bien des types de chauffage d’appoint
					comme le poêle à bois, le radiateur soufflant.
				</p>
				<p>
					<strong>Chauffage électrique </strong>: Le chauffage électrique est
					simple d’utilisation et peu cher à l’installation, il ne nécessite pas
					de stockage pour l’énergie. Contrairement à d’autres solutions de
					chauffage, le chauffage électrique n’implique pas de combustion donc
					il ne génère pas de saleté ni fumée et pollution directe. L’entretien
					d’un système de chauffage électrique ne nécessite qu’un faible
					entretien.
				</p>
				<p id="energieSolaire">
					<strong>Energie solaire </strong>: L’énergie solaire est une énergie
					inépuisable et renouvelable. D’autant plus que contrairement aux
					énergies fossiles, l’énergie solaire ne rejette pas de CO2 et ne
					contribue pas au réchauffement climatique de la planète. Les coûts
					d’installation sont de moins en moins chers grâce aux avancées
					technologiques, c’est une source d’énergie qui s’avérera économique,
					voire rentable. entretien.
				</p>
				<p>
					<strong>Panneaux solaires photovoltaïques </strong>: Les panneaux
					solaires photovoltaïques produisent leur propre électricité grâce à
					l’énergie solaire, c’est le type de panneau solaire le plus connu. Ils
					représentent un investissement intéressant pour réduire sa facture
					énergétique grâce à l’autoconsommation. En effet avec un panneau
					photovoltaïque, vous produirez et consommerez votre propre
					électricité.
				</p>
				<p>
					<strong>Panneaux solaires thermiques </strong>: Les panneaux solaires
					thermiques produisent de l’eau chaude en utilisant la chaleur du
					soleil associé avec un chauffe-eau solaire. Ce système peut couvrir
					jusqu’à 80% des besoins annuels en eau chaude sanitaire d’un foyer
				</p>
				<p id="ventillation">
					<strong>Ventilation </strong>: Une bonne ventilation de votre logement
					est primordiale quand vous souhaitez l’isoler. Associé à l’isolation
					de votre logement, la ventilation permettra de faire des économies de
					chauffage considérables. Elle réduira l’humidité dans l’air, le
					renouvèlera, expulsera les polluants et les mauvaises odeurs pour
					obtenir un air sain et doux.
				</p>
				<p>
					<strong>VMC Simple flux </strong>: La VMC simple flux fonctionne tout
					au long de la journée et veille à renouveler l’air vicié elle permet
					de purifier l’air et de chasser l’humidité.
				</p>
				<p>
					<strong>VMC double flux </strong>: La VMC double flux offre des
					résultats optimaux. En plus de renouveler l’air, la VMC double flux va
					utiliser la chaleur de l’air vicié pour réchauffer l’air neuf, et
					supprime ainsi tous les effets refroidissant d’une VMC simple flux.
				</p>
				<p>
					<strong>VMC hygroréglable </strong>: La VMC hygroréglable va détecter
					l’humidité présente dans l’air, et s’adapter lorsqu’une pièce est trop
					humide ou n’a pas besoin d’être ventilée, ce qui la rend plus efficace
					qu’une VMC simple flux et moins énergivore.
				</p>
				<p id="air">
					<strong>Purificateur d’air </strong>: Les purificateurs d’air servent
					à purifier l’air de votre logement des pollutions courantes comme les
					virus, la poussière, la fumée de cigarette.
				</p>
				<p id="co">
					<strong>Détecteur de CO² </strong>: Il est recommandé d’installer un
					détecteur de monoxyde de carbone (CO2) qui vous avertira lorsque le
					taux de CO2 dans l’air est trop important et vous permettra d’assurer
					la sécurité des occupants de votre logement.
				</p>
				<p id="borne">
					<strong>Borne de recharge </strong>: La borne de recharge est un
					incontournable quand on possède une voiture électrique. En effet,
					recharger sa voiture chez soi est simple et très agréable. De plus
					c’est très économique comparé aux bornes de la station-service mais
					également plus respectueux de l’environnement.
				</p>
				<p id="prime">
					<strong>Combien Ma Prime </strong>: Nos équipes vous accompagne pour
					l’obtention des primes sur le plan administratif et financiers
				</p>
				<h2 className="title-solutions-modal">
					Solutions RGE (Les RGE de GDE)
				</h2>
				<p>
					<strong>Accompagnement commercial </strong>: Nous vous accompagnons
					dans le développement commercial de votre société en vous fournissant
					des leads qualifiés.
				</p>
				<p>
					<strong>Gestion administrative </strong>: Oubliez l’administratif et
					concentrez-vous sur votre activité. Global Digital Ecology s’occupe de
					votre comptabilité, de l’organisation de vos ressources humaines et de
					la gestion des dossiers primes de vos clients.
				</p>
				<p>
					<strong>Certification & Formation </strong>: Nos experts vous
					accompagne dans vos démarches à l’obtention des certifications{' '}
					<u>Qualibat, QualiPV, QualiPAC</u> et borne de recharge.
				</p>
				<p>
					<strong>Accompagnement technique </strong>: Nous mettons à votre
					disposition nos équipes d’architectes & assistants maitre d’ouvrage
					pour la réalisation des demandes préalables à l’urbanisme.
				</p>
				<p>
					<strong>Aide juridique </strong>: Pour tout problème juridique lié à
					votre activité professionnelle nous vous mettrons en relation avec nos
					partenaires professionnels du droit qui sauront vous aider, répondre à
					vos questions et gérer de manière efficace votre problème juridique
					rapidement.
				</p>
			</div>
		</>
	)
}

export default SolutionModal
