import React, { useState } from 'react'
import {
	openNav,
	seconnecter,
	handshake,
	contact,
	agenda,
	score,
	chatBot,
} from '../assets/icons'
import logo from '../assets/logo.jpeg'
import '../styles/Nav.css'
import { MenuRight } from '../components/nav'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useWidth } from '../Hooks'
import { useSelector } from 'react-redux'

function Navbar() {
	const { profile, isSignedIn, name } = useSelector(state => state.user)
	const width = useWidth()
	const navigation = useHistory()
	const [navMenu, setNavMenu] = useState(false)
	const navigateToLogin = () => {
		navigation.push('/se-connecter')
	}

	return (
		<div className="container">
			{width > 1000 ? (
				<>
					<Link to="/" className="height100">
						<img src={logo} className="App-logo" alt="logo" />
					</Link>
					{!isSignedIn && (
						<>
							<NavLink to="/devis" className="web-list">
								Espace Particulier
							</NavLink>
							<NavLink exact to="/devenir-partenaire" className="web-list">
								Espace Entreprise RGE
							</NavLink>
						</>
					)}
					{profile === 'Particulier' && (
						<NavLink exact to="/" className="web-list">
							Espace Particulier
						</NavLink>
					)}

					{profile === 'Partenaire' && (
						<NavLink exact to="/" className="web-list">
							Espace Entreprise RGE
						</NavLink>
					)}
					<NavLink to="/solutions" className="web-list">
						Nos solutions
					</NavLink>
					<NavLink to="/contact" className="web-list">
						Contact
					</NavLink>
					{!isSignedIn ? (
						<button onClick={() => navigateToLogin()} className="button">
							S'identifier
						</button>
					) : (
						<li className="web-list">
							Bonjour <span className="color-primary">{name}</span>
						</li>
					)}
				</>
			) : width > 600 ? (
				<>
					{navMenu && <MenuRight setNavMenu={setNavMenu} />}
					<img
						src={openNav}
						alt="open Navigation"
						onClick={() => setNavMenu(true)}
					/>
					<Link to="/" className="height100">
						<img src={logo} className="App-logo" alt="logo" />
					</Link>
					{!isSignedIn ? (
						<button onClick={() => navigateToLogin()} className="button">
							S'identifier
						</button>
					) : (
						<li className="web-list">
							Bonjour <span className="color-primary">{name}</span>
						</li>
					)}
				</>
			) : (
				<>
					<Link to="/" className="height100">
						<img src={logo} className="App-logo" alt="logo" />
					</Link>
					{navMenu && <MenuRight setNavMenu={setNavMenu} />}
					<div className="mobile-menu">
						<div
							className="phone-nav-item"
							onClick={() => setNavMenu(!navMenu)}
						>
							<img src={openNav} className="nav-icon" alt="logo" />
							<span className="nav-phone-text">Menu</span>
						</div>
						{!isSignedIn && (
							<>
								<NavLink
									to="/se-connecter"
									activeClassName="active-mobile-Nav"
									className="phone-nav-item"
								>
									<img src={seconnecter} className="nav-icon" alt="logo" />
									<span className="nav-phone-text">se connecter</span>
								</NavLink>
								<NavLink
									to="/devenir-partenaire"
									activeClassName="active-mobile-Nav"
									className="phone-nav-item"
								>
									<img src={handshake} className="nav-icon" alt="logo" />
									<span className="nav-phone-text">
										Devenir professionel de confiance
									</span>
								</NavLink>
								<NavLink
									to="/contact"
									activeClassName="active-mobile-Nav"
									className="phone-nav-item"
								>
									<img src={contact} className="nav-icon" alt="logo" />
									<span className="nav-phone-text">Nous contacter</span>
								</NavLink>
							</>
						)}
						{profile && (
							<>
								<div className="phone-nav-item">
									<img src={agenda} className="nav-icon" alt="logo" />
									<span className="nav-phone-text">Agenda</span>
								</div>
								<div className="phone-nav-item">
									<img src={score} className="nav-icon" alt="logo" />
									<span className="nav-phone-text">Score du Partenaire</span>
								</div>
								<div className="phone-nav-item">
									<img src={chatBot} className="nav-icon" alt="logo" />
									<span className="nav-phone-text">chatbot</span>
								</div>
							</>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default Navbar
