import React, { useState } from 'react'
import {
	air,
	airWhite,
	chauffage,
	chauffageWhite,
	co,
	coWhite,
	energie,
	energieSolaireWhite,
	energieSolaire,
	energieWhite,
	appartementWhite,
	appartement,
	renovationWhite,
	renovation,
	ventillationWhite,
	ventillation,
	primeWhite,
	prime,
} from '../../assets/devisIcons'
import {
	ChauffageModel,
	IsolationModel,
	EnergieSolaireModel,
	PrimeModal,
	VentillationModel,
} from './models'

function Besoins({ besoinsData, reset, setReset, setBesoinsData }) {
	const [openModels, setOpenModels] = useState({
		chauffageModelIsOpen: false,
		IsolationModelIsOpen: false,
		EnergieSolaireModelIsOpen: false,
		PrimeModalIsOpen: false,
		VentillationModelIsOpen: false,
	})

	const findOption = option => {
		return besoinsData.besoins.find(elemment => elemment.nom === option)
			? true
			: false
	}
	const addBesoin = nom => {
		findOption(nom)
			? setBesoinsData({
					...besoinsData,
					besoins: besoinsData.besoins.filter(
						option =>
							option !==
							besoinsData.besoins.find(elemment => elemment.nom === nom)
					),
			  })
			: setBesoinsData({
					...besoinsData,
					besoins: [
						...besoinsData.besoins,
						{
							nom,
						},
					],
			  })
	}
	const IsolationIsEmpty = obj => {
		let check = true
		for (const option in obj) {
			if (option === 'nom') continue
			else if (obj[option]) {
				check = false
			}
		}
		return check
	}
	const addOptionToOptions = option => {
		setBesoinsData({
			...besoinsData,
			besoins: [
				...besoinsData.besoins,
				{
					...option,
				},
			],
		})
	}
	const removeOptionToOptions = obj => {
		setBesoinsData({
			...besoinsData,
			besoins: besoinsData.besoins.filter(option => option.nom !== obj.nom),
		})
	}
	const addBesoinObjet = obj => {
		if (obj.nom === 'Isolation') {
			if (!IsolationIsEmpty(obj) && !findOption(obj.nom))
				addOptionToOptions(obj)
			else if (!IsolationIsEmpty(obj) && findOption(obj.nom)) {
				removeOptionToOptions(obj)
				addOptionToOptions(obj)
			} else removeOptionToOptions(obj)
		} else {
			if (obj.option.length > 0 && !findOption(obj.nom)) {
				addOptionToOptions(obj)
			} else if (obj.option.length > 0 && findOption(obj.nom)) {
				removeOptionToOptions(obj)
				addOptionToOptions(obj)
			} else {
				removeOptionToOptions(obj)
			}
		}
	}
	return (
		<div className="flex  column width-80">
			<label className="label-text margin-top-1rem">Besoin de ?</label>
			<div className="card-form-container wrap margin-top-05rem">
				<button
					className={
						findOption('Isolation')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						setOpenModels({
							...openModels,
							IsolationModelIsOpen: true,
						})
					}}
					type="button"
				>
					<img
						src={findOption('Isolation') ? appartementWhite : appartement}
						alt="Isolation"
						className="home-icon"
					/>
					Isolation
				</button>
				<button
					className={
						findOption('Rénovation')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						addBesoin('Rénovation')
					}}
					type="button"
				>
					<img
						src={findOption('Rénovation') ? renovationWhite : renovation}
						alt="Rénovation"
						className="home-icon"
					/>
					Rénovation
				</button>
				<button
					className={
						findOption('Bilan Énérgetique')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						addBesoin('Bilan Énérgetique')
					}}
					type="button"
				>
					<img
						src={findOption('Bilan Énérgetique') ? energieWhite : energie}
						alt="Bilan Énérgetique"
						className="home-icon"
					/>
					Bilan Énérgetique
				</button>
				<button
					className={
						findOption('Chauffage')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						setOpenModels({
							...openModels,
							chauffageModelIsOpen: true,
						})
					}}
					type="button"
				>
					<img
						src={findOption('Chauffage') ? chauffageWhite : chauffage}
						alt="Chauffage"
						className="home-icon"
					/>
					Chauffage
				</button>
				<button
					className={
						findOption('Energie Solaire')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						setOpenModels({
							...openModels,
							EnergieSolaireModelIsOpen: true,
						})
					}}
					type="button"
				>
					<img
						src={
							findOption('Energie Solaire')
								? energieSolaireWhite
								: energieSolaire
						}
						alt="Énérgie solaire"
						className="home-icon"
					/>
					Énérgie solaire
				</button>
				<button
					className={
						findOption('Ventillation')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						setOpenModels({
							...openModels,
							VentillationModelIsOpen: true,
						})
					}}
					type="button"
				>
					<img
						src={findOption('Ventillation') ? ventillationWhite : ventillation}
						alt="Ventillation"
						className="home-icon"
					/>
					Ventilation
				</button>
				<button
					className={
						findOption('Installation de purificateur d’air')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						addBesoin('Installation de purificateur d’air')
					}}
					type="button"
				>
					<img
						src={
							findOption('Installation de purificateur d’air') ? airWhite : air
						}
						alt="Installation de purificateur d’air"
						className="home-icon"
					/>
					Installation de purificateur d’air
				</button>
				<button
					className={
						findOption('Installation de détecteur CO2')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						addBesoin('Installation de détecteur CO2')
					}}
					type="button"
				>
					<img
						src={findOption('Installation de détecteur CO2') ? coWhite : co}
						alt="Installation de détecteur CO2"
						className="home-icon"
					/>
					Installation de détecteur CO2
				</button>
				<button
					className={
						findOption('Installation de Bome de recharge')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						addBesoin('Installation de Bome de recharge')
					}}
					type="button"
				>
					<img
						src={
							findOption('Installation de Bome de recharge')
								? appartementWhite
								: appartement
						}
						alt="Bome de recharge"
						className="home-icon"
					/>
					Installation de Borne de recharge
				</button>
				<button
					className={
						findOption('Prime')
							? 'card-form margin-top-1rem width-17p height-9rem big column bg-linear color-white'
							: 'card-form margin-top-1rem width-17p height-9rem big column'
					}
					onClick={() => {
						setOpenModels({
							...openModels,
							PrimeModalIsOpen: true,
						})
					}}
					type="button"
				>
					<img
						src={findOption('Prime') ? primeWhite : prime}
						alt="prime"
						className="home-icon"
					/>
					Combien ma prime ?
				</button>
			</div>
			<IsolationModel
				reset={reset}
				setReset={setReset}
				openModels={openModels}
				addBesoinObjet={addBesoinObjet}
				setOpenModels={setOpenModels}
			/>
			<ChauffageModel
				reset={reset}
				setReset={setReset}
				openModels={openModels}
				addBesoinObjet={addBesoinObjet}
				setOpenModels={setOpenModels}
			/>
			<EnergieSolaireModel
				reset={reset}
				setReset={setReset}
				openModels={openModels}
				addBesoinObjet={addBesoinObjet}
				setOpenModels={setOpenModels}
			/>
			<VentillationModel
				reset={reset}
				setReset={setReset}
				openModels={openModels}
				addBesoinObjet={addBesoinObjet}
				setOpenModels={setOpenModels}
			/>
			<PrimeModal
				reset={reset}
				setReset={setReset}
				openModels={openModels}
				addBesoinObjet={addBesoinObjet}
				setOpenModels={setOpenModels}
			/>
		</div>
	)
}

export default Besoins
