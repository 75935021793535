import { PartenaireHome } from './container/partenaire'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserSession } from './store/actions/authActions'
import {
	Home,
	Devis,
	Solutions,
	DevenirPartenaire,
	ResetPassword,
	SeConnecter,
	Contact,
} from './container'
import { Route, Redirect } from 'react-router-dom'
import { Particulier } from './container/particulier'

function Routes() {
	const dispatch = useDispatch()
	const { isSignedIn, profile } = useSelector(state => state.user)
	useEffect(() => {
		dispatch(getUserSession())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSignedIn])
	if (isSignedIn && profile === 'Partenaire')
		return (
			<>
				{isSignedIn && <Redirect to="/" />}
				<Route exact path="/" component={PartenaireHome} />
				<Route path="/solutions" component={Solutions} />
				<Route path="/contact" component={Contact} />
			</>
		)
	else if (isSignedIn && profile === 'Particulier')
		return (
			<>
				{isSignedIn && <Redirect to="/" />}
				<Route exact path="/" component={Particulier} />
				<Route path="/solutions" component={Solutions} />
				<Route path="/contact" component={Contact} />
			</>
		)
	return (
		<>
			<Route exact path="/" component={Home} />
			<Route path="/devis" component={Devis} />
			<Route path="/devenir-partenaire" component={DevenirPartenaire} />
			<Route path="/se-connecter" component={SeConnecter} />
			<Route path="/solutions" component={Solutions} />
			<Route path="/reinitiliser-mot-de-passe" component={ResetPassword} />
			<Route path="/contact" component={Contact} />
		</>
	)
}

export default Routes
