import * as actionTypes from '../actions/TYPES'

const initialState = {
	isSignedIn: false,
	loading: false,
	email: '',
	name: '',
	verifiedEmail: false,
	siret: '',
	errorMessage: false,
	profile: '',
	newPassRequired: false,
	passToChange: '',
}

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.START_REQUEST: {
			return {
				...state,
				loading: true,
			}
		}
		case actionTypes.END_REQUEST: {
			return {
				...state,
				loading: false,
			}
		}
		case actionTypes.LOGIN: {
			const data = action.payload.idToken.payload
			let name = ''
			if (data['custom:raisonsociale'] !== undefined)
				name = data['custom:raisonsociale']
			else name = `${data['custom:prenom']} ${data['custom:nom']}`

			return {
				...state,
				isSignedIn: true,
				verifiedEmail: data['email_verified'],
				siret: data['custom:siret'],
				profile: data['custom:profil'],
				email: data['email'],
				errorMessage: '',
				newPassRequired: false,
				name,
			}
		}
		case actionTypes.VERIFY_SESSION:
			{
				const data = action.payload.idToken.payload
				let name = ''
				if (data['custom:raisonsociale'] !== undefined)
					name = data['custom:raisonsociale']
				else name = `${data['custom:prenom']} ${data['custom:nom']}`
				if (state.isSignedIn === false)
					return {
						...state,
						isSignedIn: true,
						verifiedEmail: data['email_verified'],
						siret: data['custom:siret'],
						profile: data['custom:profil'],
						email: data['email'],
						name,
						errorMessage: false,
						newPassRequired: false,
					}
			}
			break
		case actionTypes.NEW_PASS: {
			return {
				...state,
				newPassRequired: true,
				email: action.payload.email,
				passToChange: action.payload.password,
			}
		}
		case actionTypes.PASS_CHANGED: {
			return {
				...state,
				newPassRequired: false,
				passToChange: '',
			}
		}
		case actionTypes.AUTH_ERROR: {
			return {
				...state,
				errorMessage: true,
			}
		}
		case actionTypes.LOGOUT: {
			return {
				...state,
				isSignedIn: false,
				loading: false,
				email: '',
				photoURL: '',
				verifiedEmail: false,
				siret: '',
				errorMessage: '',
				profile: '',
				newPassRequired: false,
			}
		}

		default:
			return { ...state }
	}
}
export default authReducer
