import React, { useEffect } from 'react'
import {
	HomeHeader,
	// VideoHome,
	// FAQ,
	// Slider,
	// Actualite,
	Mission,
	PostFooter,
} from '../components/home'
import { Email, PhoneTopRight } from '../components/shared'
import { useLocation } from 'react-router-dom'
import { useWidth } from '../Hooks'

function Home() {
	const width = useWidth()
	const { pathname } = useLocation()
	useEffect(() => {
		window.scrollTo({
			top: 0,
		})
	}, [pathname])
	return (
		<>
			{width < 1000 && width > 600 && <div className="padding-top"></div>}
			<PhoneTopRight />
			<HomeHeader />
			{/* <VideoHome /> */}
			{/* <FAQ /> */}
			{/* <Slider /> */}
			{/* <Actualite /> */}
			<Email />
			<Mission />
			<PostFooter />
		</>
	)
}

export default Home
