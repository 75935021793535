import { home, brand } from '../../assets/icons' //add brand for the other button
import { Link } from 'react-router-dom'
import '../../styles/Home.css'

function HomeHeader() {
	return (
		<div>
			<div className="flex column margin-top-5">
				<div className="flex column center">
					<h1 className="no-margin color-secondary big-font">
						GLOBAL DIGITAL ECOLOGY
					</h1>
					<h2 className="no-margin color-secondary medium-font">
						ACCOMPAGNATEUR DE LA TRANSITION ÉCOLOGIQUE
					</h2>
				</div>
				<div className="flex center margin-top-2 gap-6 ">
					<Link
						to="/devis"
						style={{ textDecoration: 'none' }}
						className="card-container flex column bg-secondary center"
					>
						<img src={home} alt="maison" className="img-header" />
						<p className="card-text">Demander des devis</p>
					</Link>
					<Link
						to="/devenir-partenaire"
						style={{ textDecoration: 'none' }}
						className="card-container flex column bg-linear center"
					>
						<img src={brand} alt="brand" className="img-brand" />
						<p className="card-text">Devenir Partenaire</p>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default HomeHeader
