import { Navbar, Footer } from './container'
import Routes from './Routes'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
	return (
		<Router>
			<Navbar />
			<Switch>
				<Routes />
			</Switch>
			<Footer />

			{/* pour afficher les notifications  */}
			<ToastContainer />
		</Router>
	)
}

export default App
