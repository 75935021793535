import { useState } from 'react'
import { useWidth } from '../../Hooks'
import { useDispatch } from 'react-redux'
import { sendContact } from '../../store/actions/guestActions'
import { toast } from 'react-toastify'

function Form() {
	const width = useWidth()
	const dispatch = useDispatch()
	const [nom, setNom] = useState('')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [prenom, setPrenom] = useState('')
	const [showError, setShowError] = useState(false)
	const [errors, setErrors] = useState({
		email: false,
		nom: false,
		prenom: false,
		message: false,
	})

	const validateEmail = email => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	}
	const checkEmail = () => {
		if (!validateEmail(email)) {
			toast.error('Email invalide')
			setErrors({
				...errors,
				email: true,
			})
		} else {
			setErrors({
				...errors,
				email: false,
			})
		}
	}
	const checkMessage = () => {
		if (message.length < 10) {
			toast.error('Message trop court')
			setErrors({
				...errors,
				message: true,
			})
		} else {
			setErrors({
				...errors,
				message: false,
			})
		}
	}

	const formIsValid = () => {
		if (!nom) return false
		else if (!prenom) return false
		else if (message.length < 10) return false
		else if (!validateEmail(email)) return false
		return true
	}
	const cleanForm = () => {
		setEmail('')
		setMessage('')
		setNom('')
		setPrenom('')
	}

	const handleSubmit = e => {
		e.preventDefault()
		if (formIsValid()) {
			dispatch(sendContact({ email, message, prenom, nom }))
			cleanForm()
			toast.success('Votre message a été envoyé')
		} else {
			toast.error('Veuillez remplir le formulaire')
			setShowError(true)
		}
	}
	return (
		<>
			{width > 1000 && <hr className="line-contact" />}
			<form onSubmit={e => handleSubmit(e)} className="contact-form-container">
				<h1>Decrivez-nous votre besoin...</h1>
				<input
					type="text"
					value={nom}
					onChange={e => setNom(e.target.value)}
					name="nom"
					placeholder="Nom..."
					id={`${showError && !nom && 'error-border'}`}
				/>
				<input
					type="text"
					value={prenom}
					name="prenom"
					placeholder="Prenom..."
					onChange={e => setPrenom(e.target.value)}
					id={`${showError && !nom && 'error-border'}`}
				/>
				<input
					type="email"
					value={email}
					onBlur={() => checkEmail()}
					onChange={e => setEmail(e.target.value)}
					name="email"
					placeholder="Adresse mail..."
					id={`${
						(errors.email || (showError && !validateEmail(email))) &&
						'error-border'
					}`}
				/>
				<textarea
					id={`${
						(errors.message || (showError && message < 10)) && 'error-border'
					}`}
					onBlur={() => checkMessage()}
					value={message}
					onChange={e => setMessage(e.target.value)}
					cols="30"
					rows="10"
					placeholder="votre message..."
				></textarea>
				<button type="submit">envoyer</button>
			</form>
		</>
	)
}

export default Form
