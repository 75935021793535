import React from 'react'
import { maison, appartement } from '../../assets/devisIcons'
import { useWidth } from '../../Hooks'
import { Link } from 'react-router-dom'

function HeaderSolutions() {
	const width = useWidth()
	return (
		<>
			<div className="img-meeting margin-top-5">
				<div className="solutions-text">
					<h1>
						Description Missions <br />
						GLOBAL DIGITAL ECOLOGY
					</h1>
					<hr />
					<p>
						Global Digital Ecology est une entreprise qui a pour vocation de
						faciliter les relations entre les porteurs de projets (particuliers,
						propriétaires bailleurs, copropriétés) et les société RGE en mettant
						à leur disposition des outils et services pour à aider chacun
						d'entre eux tel que les gestions administratives des dossiers,
						l'accompagnement à la formation etc. <br />
						Les partenariats mis en place par Gloval Digital Ecology on pour but
						de proposer des sociétés et artisans qualifiés présentant les
						meilleures aptitudes à fournir un travail de qualité, et à respecter
						leur engagements vis-à-vis de nos clients tout au long des chantiers
						confiés
					</p>
				</div>
			</div>
			<div className="services-container">
				<div className="service-cart">
					<img src={maison} alt="maison" />
					<h1>Service pour particulier</h1>
					<hr />
					<h2>Confiez nous l'administration de vos chantiers</h2>
					<p>
						Des spécialistes vous aideront à développer une solution adaptée à
						vos besoins.
					</p>
					{/* a voir  */}
					<Link to="/devis" className="button-link">
						<button>demander un devis</button>
					</Link>
					<small>Un expert travaux prendra contact avec vous sous 48H</small>
				</div>
				<div className="service-cart">
					<img src={appartement} alt="maison" />
					<h1>Service pour entreprise RGE</h1>
					<hr />
					<h2>Decouvrez notre ALL in ONE plateforme</h2>
					<p>Nous vous accompagnons tout le long de vos projets.</p>
					<Link to="/devenir-partenaire" className="button-link">
						<button>Devenir partenaire</button>
					</Link>
					<small>
						Un conseiller travaux prendra contact avec vous sous 48H
					</small>
				</div>
			</div>
		</>
	)
}

export default HeaderSolutions
